import { Circle, CircleProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getArc(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<CircleProps> = await getBaseCommonProps(data, opt)
  option.radius = data.width / 2
  option.startAngle = data.shape?.startAngle || -90
  option.endAngle = data.shape?.endAngle || 0
  if (option.stroke && typeof option.stroke == 'object') {
    option.stroke.offsetY = -((data.height / 2) - option.radius)
  }
  // if (option.fill && typeof option.fill == 'object' && option.startAngle >= -90 && option.endAngle <= 90) {
  //   option.fill.offsetX = (data.width - (data.border?.width || 0)) / 2
  // }
  option.scaleY = (data.height / 2) / option.radius

  return new Circle(option)
}