import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getChevron(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5
  const borderWidth = Math.max(width, height) - Math.min(width, height) * radius

  return new Polygon([
    { x: 0, y: 0 },
    { x: borderWidth, y: 0 },
    { x: width, y: height / 2 },
    { x: borderWidth, y: height },
    { x: 0, y: height },
    { x: width - borderWidth, y: height / 2 },
  ], option)
}