import { useEffect, useState } from 'react';
import { Table, Form, Input, Button, Select, Tag, Grid, DatePicker } from '@arco-design/web-react';
import { IconSearch } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';

import { getOrderList } from '@/api/order';

import { formatDate } from '@/utils';

export default () => {
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const { RangePicker } = DatePicker;

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 80
		},
		{
			title: '用户id',
			dataIndex: 'userId'
		},
		{
			title: '订单编号',
			dataIndex: 'orderNo'
		},
		{
			title: '订单类型',
			dataIndex: 'type',
			render: (_: any, record: any) => {
				if (record.type == 'membership') {
					return '开通会员';
				}
				if (record.type == 'recharge') {
					return '充值钻石';
				}
				if (record.type == 'pull') {
					return 'PPT下载';
				}
			}
		},
		{
			title: '支付金额',
			dataIndex: 'payAmount',
			render: (_: any, record: any) => (
				<strong style={{ color: '#f53f3f' }}>¥{(record.payAmount / 100).toFixed(2)}</strong>
			)
		},
		{
			title: '支付渠道',
			key: 'payChannel',
			render: (_: any, record: any) => {
				if (record.payChannel == 'unified') {
					return '免费';
				}
				if (record.payChannel == 'wechat') {
					return '微信支付';
				}
				if (record.payChannel == 'alipay') {
					return '支付宝支付';
				}
			}
		},
		// {
		// 	title: '支付方式',
		// 	key: 'payType', // unified,wxpay,wx_h5,wx_native,alipay,account_coin
		// 	render: (_: any, record: any) => {
		// 		if (record.payType == 'wx_native') {
		// 			return '微信支付';
		// 		}
		// 		if (record.payType == 'alipay') {
		// 			return '支付宝支付';
		// 		}
		// 	}
		// },
		{
			title: '订单状态',
			key: 'status',
			render: (_: any, record: any) => (
				<>
					{record.status == 10 && <Tag color="#86909c">待支付</Tag>}
					{record.status == 20 && <Tag color="#00b42a">已支付</Tag>}
					{record.status == 30 && <Tag color="#86909c">已过期</Tag>}
					{record.status == 40 && <Tag color="#ff7d00">待退款</Tag>}
					{record.status == 50 && <Tag color="#86909c">已退款</Tag>}
				</>
			)
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			render: (_: any, record: any) => <>{record.createTime ? formatDate(record.createTime) : '-'}</>
		},
		{
			title: '支付时间',
			dataIndex: 'payTime',
			render: (_: any, record: any) => <>{record.payTime ? formatDate(record.payTime) : '-'}</>
		},
		{
			title: '备注',
			dataIndex: 'remark'
		}
	];

	const payType = [
		{
			label: '免费',
			value: 'unified'
		},
		{
			label: '微信支付',
			value: 'wechat'
		},
		{
			label: '支付宝支付',
			value: 'alipay'
		}
	];
	const orderType = [
		{
			label: 'AI充值',
			value: 'recharge'
		},
		{
			label: 'PPT下载',
			value: 'pull'
		}
	];
	// 10、待支付 20、已支付 30、已过期 40、待退款 50、已退款
	const orderStatus = [
		{
			label: '待支付',
			value: 10
		},
		{
			label: '已支付',
			value: 20
		},
		{
			label: '已过期',
			value: 30
		},
		{
			label: '待退款',
			value: 40
		},
		{
			label: '已退款',
			value: 50
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};
	const handleSearch = () => {
		if (page == 1) {
			getList();
		} else {
			setPage(1);
		}
	};

	const getList = async () => {
		setLoading(true);
		const values = form.getFieldsValue();

		if (values.rangeTimes && values.rangeTimes.length == 2) {
			values.startTime = new Date(values.rangeTimes[0] + ' 00:00:00').getTime();
			values.endTime = new Date(values.rangeTimes[1] + ' 23:59:59').getTime();
			delete values.rangeTimes;
		}

		let res = await getOrderList({
			...values,
			page,
			size: 20
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	// 统计
	const tableStatistics = (currentData?: any[]) => {
		return (
			<Table.Summary.Row>
				<Table.Summary.Cell>统计</Table.Summary.Cell>
				<Table.Summary.Cell colSpan={9}>
					总金额：
					<strong style={{ color: '#f53f3f' }}>
						¥{(currentData?.reduce((prev, next) => prev + next.payAmount, 0) / 100).toFixed(2)}
					</strong>
				</Table.Summary.Cell>
			</Table.Summary.Row>
		);
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={8}>
							<FormItem field="userId" label="用户id">
								<Input allowClear placeholder="请输入用户id" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="orderNo" label="订单编号">
								<Input allowClear placeholder="请输入订单编号" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="status" label="订单状态">
								<Select allowClear placeholder="请选择订单状态" options={orderStatus} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="payChannel" label="支付渠道">
								<Select allowClear placeholder="请选择支付渠道" options={payType} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="type" label="订单类型">
								<Select allowClear placeholder="请选择订单类型" options={orderType} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="rangeTimes" label="创建时间">
								<RangePicker
									allowClear
									shortcuts={[
										{
											text: '近7天',
											value: () => [dayjs(), dayjs().subtract(1, 'week')]
										},
										{
											text: '近30天',
											value: () => [dayjs(), dayjs().subtract(1, 'month')]
										},
										{
											text: '近1个季度',
											value: () => [dayjs(), dayjs().subtract(3, 'month')]
										},
										{
											text: '近1年',
											value: () => [dayjs(), dayjs().subtract(1, 'year')]
										}
									]}
								/>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem>
								<Button type="primary" icon={<IconSearch />} htmlType="submit">
									搜索
								</Button>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					summary={tableStatistics}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 20,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
