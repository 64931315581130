import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getDiamond(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const { width, height } = data

  return new Polygon([
    { x: 0, y: height / 2 },
    { x: width / 2, y: 0 },
    { x: width, y: height / 2 },
    { x: width / 2, y: height },
  ], option)
}