import Color from 'color'
// import { encodeSVGPath } from 'svg-pathdata'

import { Xfrm, ColorData, PathLst, Fill, ColorModsKeys } from '@/types/source'
import { LineEndType, LineType, ShapeType, Gradient, ColorInfo } from '@/types/element'
import { EMU_PER_PX, emuToAngle, emuToPercent, emuToPixel } from './unit'

export type ThemeColorScheme = {
  [k: string]: string
}

interface BaseXfrm {
  width: number,
  height: number,
  left: number,
  top: number,
  flipX: boolean,
  flipY: boolean,
  rot: number,
  noPosition?: boolean
}

export function getBaseXfrmInfo(data?: Xfrm): BaseXfrm {
  if (!data) {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      flipX: false,
      flipY: false,
      rot: 0,
      noPosition: true,
    }
  }
  const { off, ext } = data
  const { x, y } = off
  const { cx, cy } = ext
  
  return {
    width: emuToPixel(cx),
    height: emuToPixel(cy),
    left: emuToPixel(x),
    top: emuToPixel(y),
    flipX: data.flipH || false,
    flipY: data.flipV || false,
    rot: emuToAngle(data.rot || 0),
  }
}

interface GroupXfrm extends BaseXfrm {
  scaleX: number,
  scaleY: number,
  offLeft: number,
  offTop: number,
}

export function getGroupXfrmInfo(data?: Xfrm): GroupXfrm {
  const option = getBaseXfrmInfo(data)
  if (!data) {
    return { ...option, scaleX: 1, scaleY: 1, offLeft: 0, offTop: 0 }
  }
  const { chExt, chOff, ext } = data

  return {
    ...option,
    scaleX: ext.cx / (chExt?.cx || ext.cx),
    scaleY: ext.cy / (chExt?.cy ||  ext.cy),
    offLeft: emuToPixel(chOff?.x || 0),
    offTop: emuToPixel(chOff?.y || 0)
  }
}

export function getXfrmInfo(data?: Xfrm, groupData?: Xfrm[]): {
  width: number,
  height: number,
  left: number,
  top: number,
  flipX: boolean,
  flipY: boolean,
  rot: number
} {
  if (!data) {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      flipX: false,
      flipY: false,
      rot: 0,
    }
  }
  const { off, ext } = data
  let left = off.x
  let top = off.y
  let width = ext.cx
  let height = ext.cy

  if (groupData) {
    for (const group of groupData.reverse()) {
      const { cx = 0, cy = 0 } = group.chExt || {}
      const radioX = (group.ext.cx || 0) / cx
      const radioY = (group.ext.cy || 0) / cy
      width = radioX * width
      height = radioY * height
      left = (left - (group.chOff?.x || 0)) * radioX + group.off.x
      top = (top - (group.chOff?.y || 0)) * radioY + group.off.y
    }
  }

  return {
    width: emuToPixel(width),
    height: emuToPixel(height),
    left: emuToPixel(left),
    top: emuToPixel(top),
    flipX: data.flipH || false,
    flipY: data.flipV || false,
    rot: emuToAngle(data.rot || 0),
  }
}

export function getFill(color: ColorData) {
  const { val, type, mods = {}, lastClr, r, g, b, h, s, l } = color
  // lumMod: 亮度，satMod: 饱和度
  // const { alpha, lumMod = 0, lumOff = 0, satMod = 0, satOff = 0, tint = 0, shade = 0, hueOff = 0, hueMod = 0 } = mods

  let value = '#000000'
  switch (type) {
    case 1:
      value = `#${val}`
      break
    case 2:
      value = val
      break
    case 3:
      value = Color({ h, s, l }).hex()
      break
    case 4:
      value = Color({ r, g, b }).hex()
      break
    case 5:
      value = `#${lastClr}`
      break
    case 6:
      value = Color(val).hex()
      break
    default:
  }

  const _mods: ColorInfo['mods'] = {}

  Object.entries(mods).forEach(([key, value]) => {
    _mods[key as ColorModsKeys] = emuToPercent(value)
  })

  return {
    type: type == 2 ? 2 : 1,
    value,
    mods: _mods
  }
}

export function getGradientColorStops(gsLst: Fill['gsLst']) {
  const list: Gradient['colorStops'] = (gsLst || []).map(item => {
    return {
      offset: emuToPercent(item.pos),
      color: getFill(item.color),
    }
  }).sort((a, b) => a.offset - b.offset)

  return list
}

export function checkValidFill (fill: Fill) {
  if (fill.color || fill.gsLst) return true
  return false
}

export function getCustomShape(pathLst: PathLst) {
  const { w, h, ts } = pathLst

  const width = w / EMU_PER_PX
  const height = h / EMU_PER_PX

  const shapePathTypeMap = {
    1: 'M',
    2: 'L',
    3: 'A',
    4: 'Q',
    5: 'C',
    6: 'Z',
  }

  return {
    width,
    height,
    path: ts.map(item => {
      const type = shapePathTypeMap[item.type as keyof typeof shapePathTypeMap]
      return {
        type,
        pos: (item.pos || []).map(item => item / EMU_PER_PX)
      }
    }),
  }
}

export function getBaseShapeType(type?: string): ShapeType {
  if (!type) return 'rect'
  switch(type) {
  // 圆形
  case 'ellipse':
    return 'ellipse'
  // 圆角矩形
  case 'roundRect':
    return 'roundRect'
  // 等腰三角形
  case 'triangle':
    return 'triangle'
  // 流程图提取
  case 'flowChartExtract':
    return 'flowChartExtract'
  // 流程图合并
  case 'flowChartMerge':
    return 'flowChartMerge'
  // 直角三角形
  case 'rtTriangle':
    return 'rtTriangle'
  // 上箭头
  case 'upArrow':
    return 'upArrow'
  // 下箭头
  case 'downArrow':
    return 'downArrow'
  // 左箭头
  case 'leftArrow':
    return 'leftArrow'
  // 右箭头
  case 'rightArrow':
    return 'rightArrow'
  // 向右五边形
  case 'homePlate':
    return 'homePlate'
  // 圆弧
  case 'arc':
    return 'arc'
  // 六边形
  case 'hexagon':
    return 'hexagon'
  // 平行四边形
  case 'parallelogram':
    return 'parallelogram'
  // 梯形
  case 'trapezoid':
    return 'trapezoid'
  // 钻石形
  case 'diamond':
    return 'diamond'
  // 五角形
  case 'pentagon':
    return 'pentagon'
  // 终止符
  case 'flowChartTerminator':
    return 'flowChartTerminator'
  // 同心圆
  case 'donut':
    return 'donut'
  // 燕尾形
  case 'chevron':
    return 'chevron'
  // 五角星
  case 'star5':
    return 'star5'
  // 对角圆角矩形
  case 'round2DiagRect':
    return 'round2DiagRect'
  // 同侧圆角矩形
  case 'round2SameRect':
    return 'round2SameRect'
  // 单圆角矩形
  case 'round1Rect':
    return 'round1Rect'
  // 剪去单角的矩形
  case 'snip1Rect':
    return 'snip1Rect'
  // 剪去同侧角的矩形
  case 'snip2SameRect':
    return 'snip2SameRect'
  // 剪去对角的矩形
  case 'snip2DiagRect':
    return 'snip2DiagRect'
  // 一个圆角顶并剪去另一个顶角的矩形
  case 'snipRoundRect':
    return 'snipRoundRect'
  // 加号
  case 'mathPlus':
    return 'mathPlus'
  // 减号
  case 'mathMinus':
    return 'mathMinus'
  // 乘号
  case 'mathMultiply':
    return 'mathMultiply'
  // 十字形
  case 'plus':
    return 'plus'
  // L形状
  case 'corner':
    return 'corner'
  // 斜纹
  case 'diagStripe':
    return 'diagStripe'
  // 矩形标柱
  case 'wedgeRectCallout':
    return 'wedgeRectCallout'
  // 默认矩形
  default:
    return 'rect'
  }
}

export function getLineStyle(type?: string): LineType {
  if (!type) return 'solid'
  switch(type) {
    case 'sysDot':
      return 'dot'
    case 'sysDash':
      return 'dash'
    default:
      return 'solid'
  }
}

export function getLineEndType(type?: string): LineEndType {
  if (!type) return 'none'
  switch(type) {
    case 'triangle':
      return 'triangle'
    default: 
      return 'none'
  }
}