import { Ellipse, EllipseProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getEllipse(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<EllipseProps> = await getBaseCommonProps(data, opt)
  option.rx = data.width / 2
  option.ry = data.height / 2

  return new Ellipse(option)
}