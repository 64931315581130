const baseItem = {
	tit: '这里输入页面标题,主标题,请输入页面标题',
	desc: '描述',
	logo: 'LOGO',
	bg: '背景图片'
};
const makeCatalog = (num: number) => {
	let item: any = {};
	const enType: any = {
		'1': 'one',
		'2': 'two',
		'3': 'three',
		'4': 'four',
		'5': 'five',
		'6': 'six',
		'7': 'seven',
		'8': 'eight',
		'9': 'nine',
		'10': 'ten'
	};
	for (let i = 0; i < num; i++) {
		const key = `dirs#${i + 1}@`;

		const numFill = i + 1;
		const numPatch = numFill < 10 ? '0' + numFill : numFill;

		item[`${key}seq`] = `${numFill},${numPatch},part${numFill},part${numPatch},part${enType[numFill]}`;
		item[`${key}ltit`] = `标题${numFill},输入标题${numFill},请输入标题${numFill},请输入目录标题${numFill}`;
		item[`${key}ltitEn`] = `标题${numFill}英文`;
		item[`${key}icon`] = `图标${numFill}`;
		item[`${key}pic`] = `图片${numFill}`;
	}
	return item;
};

const makeNR = (num: number, type: string) => {
	const item: any = {
		tit: '这里输入页面标题',
		logo: 'LOGO',
		bg: '背景图片'
	};
	if (type == 'p') {
		// 多段
		item.ctit = '输入结论标题';
		item.cdesc = '结论标题描述,请输入结论标题描述,总结内容';
	}
	if (type == 't') {
		// 多项
		item.desc = '描述';
	}

	for (let i = 0; i < num; i++) {
		const key = `contents#${i + 1}@`;

		item[`${key}ltit`] = `输入小标题${i + 1},小标题名称${i + 1}`;
		item[`${key}ldesc`] = `这里是描述内容${i + 1},这里是描述文本${i + 1}`;
		item[`${key}seq`] = `序号${i + 1}`;
		item[`${key}icon`] = `图标${i + 1}`;
		item[`${key}pic`] = `图片${i + 1}`;
		item[`${key}labels#1`] = `内容${i + 1}标签1`;
		item[`${key}labels#2`] = `内容${i + 1}标签2`;
		item[`${key}labels#3`] = `内容${i + 1}标签3`;
		item[`${key}labels#4`] = `内容${i + 1}标签4`;
		item[`${key}labels#5`] = `内容${i + 1}标签5`;
		item[`${key}labels#6`] = `内容${i + 1}标签6`;
	}
	return item;
};

const makePerson = (num: number) => {
	const item: any = {
		tit: '这里输入页面标题,请输入页面标题,团队成员介绍'
		// desc: '描述',
		// logo: 'LOGO',
		// bg: '背景图片'
	};
	for (let i = 0; i < num; i++) {
		const key = `members#${i + 1}@`;
		item[`${key}name`] = `姓名${i + 1}`;
		item[`${key}title`] = `职称${i + 1}`;
		item[`${key}avatar`] = `人物头像${i + 1}`;
		item[`${key}desc`] = `人物介绍${i + 1}`;
		item[`${key}honors#1`] = `人物${i + 1}主要荣誉1`;
		item[`${key}honors#2`] = `人物${i + 1}主要荣誉2`;
		item[`${key}honors#3`] = `人物${i + 1}主要荣誉3`;
		item[`${key}honors#4`] = `人物${i + 1}主要荣誉4`;
		item[`${key}honors#5`] = `人物${i + 1}主要荣誉5`;
		item[`${key}honors#6`] = `人物${i + 1}主要荣誉6`;
	}
	return item;
};

const makeTimeAxis = (num: number) => {
	const item: any = {};
	for (let i = 0; i < num; i++) {
		const key = `sections#${i + 1}@`;
		const fillMon = i + 1 < 10 ? '0' + (i + 1) : i + 1;

		item[`${key}date`] = `2024.${fillMon},2024${fillMon},2024年${fillMon}月`;
		item[`${key}ltit`] = `输入小标题${i + 1},标题${i + 1}`;
		item[`${key}icon`] = `节点${i + 1}图标`;
		item[`${key}desc`] = `这里是描述内容${i + 1}`;
		item[`${key}labels#1`] = `节点${i + 1}标签1`;
		item[`${key}labels#2`] = `节点${i + 1}标签2`;
		item[`${key}labels#3`] = `节点${i + 1}标签3`;
		item[`${key}labels#4`] = `节点${i + 1}标签4`;
		item[`${key}labels#5`] = `节点${i + 1}标签5`;
		item[`${key}labels#6`] = `节点${i + 1}标签6`;
	}
	return item;
};

const makeLogo = (num: number) => {
	const item: any = {};
	for (let i = 0; i < num; i++) {
		const key = `LOGO${i + 1}@`;
		item[`${key}ltit`] = `LOGO${i + 1}小标题`;
		item[`${key}desc`] = `LOGO${i + 1}描述`;
		item[`${key}icon`] = `LOGO${i + 1}图标`;
		item[`${key}pic`] = `LOGO${i + 1}图片`;
	}
	return item;
};

const makeThanks = (num: number) => {
	const item: any = {};
	for (let i = 0; i < num; i++) {
		const key = `thanks#${i + 1}@`;
		item[`${key}company`] = `尾页致谢${i + 1}企业名称`;
		item[`${key}sloganPic`] = `尾页致谢${i + 1}口号图片`;
		item[`${key}sloganTxt`] = `尾页致谢${i + 1}口号文本`;
	}
	return item;
};

const structs: any = {
	// 封面页
	FM: {
		tit: '工作汇报,总结汇报',
		titEn: '主标题（英文）',
		subt: '副标题',
		'content@author': '少小刻,汇报人：少小刻,汇报人:少小刻',
		'content@company': '少刻科技,公司：少刻科技,公司:少刻科技',
		'content@date': '20XX',
		logo: 'LOGO',
		bg: '背景图片'
	},
	// 目录页
	ML: {
		name: '目录,目录名称',
		nameEn: 'contents',
		icon: '目录图标',
		logo: 'LOGO',
		bg: '背景图片',
		...makeCatalog(10)
	},
	// 过渡页
	GD: {
		...baseItem,
		'struct@seq': '1,2,3',
		'struct@fillSeq': '01,02,03',
		'struct@partSeq': 'part1,part2,part3',
		'struct@partFillSeq': 'part01,part02,part03',
		'struct@partEnSeq': 'partone,parttwo,partthree',
		'struct@ltit': '输入标题名称',
		'struct@ltitEn': '小标题英文,content',
		'struct@icon': '图标',
		'struct@pic': '图片'
	},
	// 金句页 ---
	JJ: {},
	// 内容简介页 ---
	NRJJ: {},
	// 一段内容页
	NR1: {
		...makeNR(1, 'p')
	},
	// 两段内容页
	NR2: {
		...makeNR(2, 'p')
	},
	// 三段内容页
	NR3: {
		...makeNR(3, 'p')
	},
	// 四段内容页
	NR4: {
		...makeNR(4, 'p')
	},
	// 四段内容页
	NR5: {
		...makeNR(5, 'p')
	},
	// 四段内容页
	NR6: {
		...makeNR(6, 'p')
	},
	// 多段内容页
	NR: {
		...makeNR(10, 'p')
	},
	// 多项内容页
	DXNR: {
		...makeNR(10, 't')
	},
	// 对比内容页 ---
	DBNR: {},
	// 人物介绍页（单人）
	RWJS1: {
		...makePerson(1)
	},
	// 人物介绍页（多人）
	RWJS: {
		...makePerson(6)
	},
	// 文字云页 ---
	WZY: {},
	// 时间轴页
	SJZ: {
		tit: '这里输入页面标题,主标题,请输入页面标题',
		...makeTimeAxis(10)
	},
	// 地图页 ---
	DT: {},
	// 表格页 ---
	BG: {},
	// 数据展示页 ---
	SJZS: {},
	// 多图展示页 ---
	DUZS: {},
	// 逻辑图示页 ---
	LJTS: {},
	// 流程图页 ---
	LCT: {},
	// 架构图页 ---
	JGT: {},
	// 提问回答页 ---
	TWHD: {},
	// 截图展示页 ---
	JTZS: {},
	// 数据图表页 ---
	SJTB: {},
	// 多图标页 ---
	DTU: {},
	// 多LOGO页
	LOGO: {
		tit: '合作品牌,合作伙伴',
		desc: '描述',
		logo: 'LOGO',
		bg: '背景图片',
		...makeLogo(20)
	},
	// 联系方式页
	LXFS: {
		tit: '主标题,请输入页面标题',
		desc: '描述',
		logo: 'LOGO',
		bg: '背景图片',
		'contact@company': '这里输入公司名称',
		'contact@name': '这里输入联系人姓名',
		'contact@tel': '这里输入电话信息',
		'contact@email': '这里输入邮箱信息',
		'contact@address': '这里输入公司地址',
		'contact@website': '这里输入网址信息',
		'contact@wechat': '微信二维码',
		'contact@douyin': '抖音二维码',
		'contact@remark': '备注'
	},
	// 尾页致谢
	JW: {
		tit: '感谢观看,谢谢观看,THANKS',
		desc: '描述',
		logo: 'LOGO',
		bg: '背景图片',
		...makeThanks(2)
	},
	// 循环 ---
	XH: {},
	// 层级/架构 ---
	CJ: {},
	// 矩阵 ---
	JZ: {},
	// 棱锥 ---
	LZ: {},
	// 模块 ---
	MK: {},
	// 并列/列表 ---
	BL: {}
};
export default structs;
