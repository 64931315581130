import { Path, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getPlus(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25
  const size = Math.min(width, height) * radius
  const path = `
    M 0 ${size} 
    L ${size} ${size}
    L ${size} 0
    L ${width - size} 0
    L ${width - size} ${size}
    L ${width} ${size}
    L ${width} ${height - size}
    L ${width - size} ${height - size}
    L ${width - size} ${height}
    L ${size} ${height}
    L ${size} ${height - size}
    L 0 ${height - size}
    Z`

  return new Path(path, option)
}