import { Rect, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getRect(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  option.width = data.width
  option.height = data.height
  
  const rect = new Rect(option)

  return rect
}