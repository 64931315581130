import { Path, PathProps, Group } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getPath(data: Element, opt: { theme?: ThemeValue['color'] }) {
  if (!data.shape?.path?.length) return null

  const option: Partial<PathProps> = await getBaseCommonProps(data, opt)

  const { path: pathList } = data.shape

  const pathNodes = pathList.map(info => {
    const { width, height, ts } = info
    const path = new Path(ts.map(item => [...item.type, ...item.pos.map((it, i) => {
      if (i % 2 == 0) {
        return it * (data.width / width)
      }
      return it * (data.height / height)
    })]).flat().join(' '), option)

    if (data.type == 'image') {
      path.scaleX = data.width / (path.width)
      path.scaleY = data.height / (path.height)
    }

    return path
  })
  
  const group = new Group(pathNodes, option)

  return group
}