import fetch from '@/common/fetch';

// 主题列表
export const getFontList = (param: any) =>
	fetch({
		url: '/res/v1/typeface/list',
		params: param,
		method: 'get'
	});

// 字体下拉列表
export const getFontSelect = (lang: string) =>
	fetch({
		url: `/res/v1/typeface/options/${lang}`,
		method: 'get'
	});

// 添加主题
export const addFont = (data: any) =>
	fetch({
		url: '/res/v1/typeface',
		data,
		method: 'post'
	});

// 主题修改
export const putFontEdit = (id: string, data: any) =>
	fetch({
		url: `/res/v1/typeface/${id}`,
		data,
		method: 'put'
	});

// 主题删除
export const delFont = (id: string | number) =>
	fetch({
		url: `/res/v1/typeface/${id}`,
		method: 'delete'
	});

// 加载字体
export const getFontSymbol = () =>
	fetch({
		url: `/res/v1/typeface/ttf`,
		method: 'get'
	});
