import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Message } from '@arco-design/web-react';
import { IconUser, IconLock } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';
import { postLogin } from '@/api/login';
import { setCookies, setSessionStorage } from '@/utils';

import './index.less';

export default () => {
	const { updateUserInfo } = useContext(GlobalContext);
	const navigate = useNavigate();
	const FormItem = Form.Item;
	const [form] = Form.useForm();
	const [loading, setloading] = useState(false);

	const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;
	const RefTokenName = import.meta.env.VITE_APP_REFRESH_TOKEN_NAME;

	const handleSignIn = async () => {
		try {
			const values = await form.validate();
			const res: any = await postLogin(values);
			if (res.code == 200) {
				Message.success('登录成功');
				setCookies(tokenName, res.data.access_token, res.data.expires_in);
				// 设置刷新 token，存入 localStorage
				setSessionStorage(RefTokenName, res.data.refresh_token);
				updateUserInfo();
				navigate('/');
			}
		} catch (err: any) {
			console.log('err', err);
			// Message.error(err.msg);
		} finally {
			setloading(false);
		}
	};

	return (
		<div className="login-warp flex justify-center items-center">
			<div className="login-box">
				<span className="borderLine"></span>
				<div className="login-form">
					<div className="login-title">用户登录</div>
					<Form form={form} layout={'vertical'} size={'large'}>
						<FormItem field="user_name" rules={[{ required: true, message: '请输入用户名' }]}>
							<Input size="large" prefix={<IconUser />} placeholder="请输入账号" />
						</FormItem>
						<FormItem field="password" rules={[{ required: true, message: '请输入密码' }]}>
							<Input.Password size="large" prefix={<IconLock />} placeholder="请输入密码" />
						</FormItem>

						<FormItem>
							<Button size="large" loading={loading} type="primary" long onClick={handleSignIn}>
								登录
							</Button>
						</FormItem>
					</Form>
				</div>
			</div>
		</div>
	);
};
