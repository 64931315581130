import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Modal, Trigger } from '@arco-design/web-react';
import { IconCaretDown } from '@arco-design/web-react/icon';

import { getFormatStructs, getFormatBindStructs, getFormatStructsDetail } from '@/api/slides';
import { getSessionStorage } from '@/utils';

// import uploadFile, { typeEnum } from '@/utils/uploadFile';

import authStructs from '@/common/structs';

import './index.less';

interface iProps {
	slideId: string;
	slideElement: any;
	onRelBindCb: (rel: any, num?: number | null) => void;
	onRelImgCb?: (eleId: number | string, imgUrl: string) => void;
}

export default (props: iProps) => {
	const { id } = useParams();
	// const { slideId, slideElement, onRelBindCb, onRelImgCb } = props;
	const { slideId, slideElement, onRelBindCb } = props;
	const cdnUrl = getSessionStorage('cdnUrl');

	const [popupVisible, setPopupVisible] = useState(false);
	// 版式类型列表
	const [structList, setStructList] = useState<any[]>([]);

	// 所有页面关系组合
	const [relList, setRelList] = useState<any>({});

	// 当前操作的关系
	const [currentRel, setCurrentRel] = useState<any>(null);

	// 当前页面的所有可绑定元素
	const [curElement, setCurElement] = useState<any[]>([]);

	// 编辑时 - 获取绑定关系
	const getBindInfo = async () => {
		let { code, data }: any = await getFormatBindStructs(id);
		if (code == 200) {
			const arr = data[0].split('!');
			// 版式ID
			const id = arr[0];
			const relObj: any = {};
			// 多个绑定关系
			if (arr[1].indexOf('@') > -1) {
				let b = arr[1].split('@');
				b.forEach((v: any) => {
					let c = v.split(':');
					relObj[c[0]] = c[1];
				});
			} else {
				if (arr[1].indexOf(':') > -1) {
					let c = arr[1].split(':');
					relObj[c[0]] = c[1];
				}
			}
			// 获取版式详情
			let result: any = await getFormatStructsDetail('1', id);
			if (result.code == 200) {
				result.data.models.forEach((item: any) => {
					if (item.type == 8) {
						item.children.forEach((val: any) => {
							val.bindId = '';
							if (relObj[val.id]) {
								val.bindId = relObj[val.id];
							}
						});
					} else {
						item.bindId = '';
						if (relObj[item.id]) {
							item.bindId = relObj[item.id];
						}
					}
				});

				return result.data;
			}
		}
	};

	// 获取版式类型列表
	const getStyleList = async () => {
		let res = await getFormatStructs('1');
		const filterArr: string[] = [
			'金句页',
			'对比内容页',
			'文字云页',
			'地图页',
			'表格页',
			'数据展示页',
			'多图展示页',
			'逻辑图示页',
			'流程图页',
			'架构图页',
			'提问回答页',
			'截图展示页',
			'数据图表页',
			'多图标页',
			'循环',
			'层级/架构',
			'矩阵',
			'棱锥',
			'模块',
			'并列/列表'
		];
		res.data = res.data.filter((item: any) => !filterArr.includes(item.label));
		setStructList(res.data);
	};

	// 获取版式详情
	const getDetail = async (id: any) => {
		try {
			const res = await getFormatStructsDetail('1', id);
			res.data.models.forEach((item: any) => {
				if (item.type == 8) {
					item.children.forEach((val: any) => {
						val.bindId = '';
					});
				} else {
					item.bindId = '';
				}
			});
			const relInfo = JSON.parse(JSON.stringify(res.data));

			// 自动绑定  curElement
			const relSymbol = relInfo.symbol;
			const symbolStruct = authStructs[relSymbol];
			// 获取Symbol的集合
			const objStruct = symbolStruct ? Object.keys(symbolStruct) : [];
			const symbolMap: Map<string, string> = new Map();
			objStruct.forEach((key) => {
				const strArr = symbolStruct[key].split(',');
				strArr.forEach((item: string) => {
					symbolMap.set(item, key);
				});
			});
			console.log('symbolMap', symbolMap);
			// 遍历元素
			curElement.forEach((item) => {
				console.log('item', item);
				if (item.type == 'text') {
					// 全匹配
					let symbol = symbolMap.get(item.text.toLowerCase());
					// 模糊匹配
					if (!symbol) {
						for (let [key, value] of symbolMap) {
							const val = key.toLowerCase();
							console.log('val', val, item.text, item.text.includes(val));
							if (!/^\d{1,2}$/.test(val) && item.text.includes(val)) {
								symbol = value;
								break;
							}
						}
					}
					console.log('symbol', symbol);
					if (symbol) {
						const symbolArr = symbol.split('@');
						relInfo.models.forEach((val: any) => {
							if (val.symbol == symbolArr[0]) {
								if (val?.children) {
									val?.children.forEach((child: any) => {
										if (child.symbol == symbolArr[1]) {
											item.bindId = child.id;
											child.bindId = item.id;
										}
									});
								} else {
									item.bindId = val.id;
									val.bindId = item.id;
								}
							}
						});
					}
				}
			});

			setCurrentRel(relInfo);
			setCurElement([...curElement]);

			setPopupVisible(false);

			relList[slideId] = relInfo;
			setRelList({ ...relList });
		} catch (error) {
			console.log('error', error);
		}
	};

	// 提取文本
	const extractText = (list: any) => {
		let text = '';
		list.forEach((item: any) => {
			if (item.text && Array.isArray(item.text) && item.text.length > 0) {
				text += extractText(item.text);
			} else {
				text += item.text.replace(/\s*/g, '');
			}
		});
		return text;
	};
	// 解析出所有的元素
	const getElementList = (list: any) => {
		const eleList: any[] = [];
		list.forEach((item: any) => {
			if (item.elements && Array.isArray(item.elements) && item.elements.length > 0) {
				const arr = getElementList(item.elements);
				arr.forEach((item) => eleList.push(item));
			} else {
				if (item.type == 'text' && item.text.length > 0) {
					let info: any = {
						id: item.id, // 元素 ID
						type: item.type, // 元素类型
						name: item.name, // 元素名称
						text: extractText(item.text), // 元素文本
						bindId: '' // 绑定ID
					};

					eleList.push(info);
				}
				if (item.type == 'image') {
					let info: any = {
						id: item.id,
						type: item.type,
						name: item.name,
						text: item.src,
						bindId: ''
					};
					eleList.push(info);
				}
			}
		});
		return eleList;
	};

	// 选择版式
	const onChangeFormat = (item: any) => {
		if (currentRel?.id) {
			Modal.confirm({
				title: '提示',
				content: '是否切换版式结构？切换后绑定关系将会清除!',
				okText: '确认',
				cancelText: '取消',
				onOk() {
					getDetail(item.value);
				},
				onCancel() {}
			});
		} else {
			getDetail(item.value);
		}
	};

	// 绑定
	const handleSelect = (item: any, idx: number) => {
		// 元素上绑定
		curElement[idx].bindId = item.id;
		// 绑定关系上绑定
		const newRel = Object.assign({}, currentRel);
		newRel?.models.forEach((val: any) => {
			if (item.id == val.id) {
				val.bindId = curElement[idx].id;
			} else {
				if (val?.children) {
					val?.children.forEach((child: any) => {
						if (item.id == child.id) {
							child.bindId = curElement[idx].id;
						}
					});
				}
			}
		});
		setCurElement([...curElement]);
		setCurrentRel(newRel);
	};
	// 取消绑定
	const handleUnSelect = (item: any) => {
		// 元素上绑定
		curElement.forEach((val) => {
			if (val.bindId == item.id) {
				val.bindId = '';
			}
		});
		// 绑定关系上绑定
		const newRel = Object.assign({}, currentRel);
		newRel?.models.forEach((val: any) => {
			if (item.id == val.id) {
				val.bindId = '';
			} else {
				if (val?.children) {
					val?.children.forEach((child: any) => {
						if (item.id == child.id) {
							child.bindId = '';
						}
					});
				}
			}
		});
		setCurElement([...curElement]);
		setCurrentRel(newRel);
	};

	// 清除当前绑定关系
	const clearRelation = () => {
		// currentRel.models.forEach((item: any) => {
		// 	if (item.type == 8) {
		// 		item.children.forEach((val: any) => {
		// 			val.bindId = '';
		// 		});
		// 	} else {
		// 		item.bindId = '';
		// 	}
		// });

		curElement.forEach((item) => {
			if (item.type == 'text') {
				item.bindId = '';
			}
		});

		setCurrentRel(null);
		setCurElement([...curElement]);
	};
	// 获取关联关系，返回给父组件
	const getBindRelation = () => {
		// console.log('currentRel', currentRel);

		if (currentRel) {
			let relKeys = Object.keys(currentRel);
			if (relKeys.length > 0) {
				console.log('currentRel', currentRel);
				console.log('curElement', curElement);
				let rel: any[] = [];
				curElement.forEach((item) => {
					if (item.bindId) {
						rel.push(`${item.bindId}:${item.id}`);
					}
				});

				console.log('rel', rel);
				const relArr = rel.map((it) => it.split(':')[0]);
				console.log('relArr', relArr);
				if (new Set(relArr).size !== relArr.length) {
					Modal.error({
						title: '提示',
						content: '当前绑定关系中有重复项，请手动重新绑定或重新上传模板！',
						onOk: () => {
							clearRelation();
						}
					});
				} else {
					let relStr = `${currentRel.id}!${rel.join('@')}`;
					console.log('relStr', relStr);
					onRelBindCb(relStr);
				}
			}
		}
	};

	/**
	 * 渲染 版式类型
	 */
	const renderStructList = () => {
		return (
			<div className="select-struct-list flex flex-wrap">
				{structList.map((item) => (
					<Button
						key={`select-struct-list-${item.value}`}
						style={{ margin: '0 5px 5px 0' }}
						size="mini"
						onClick={() => onChangeFormat(item)}>
						{item.label}
					</Button>
				))}
			</div>
		);
	};

	// 渲染绑定关系结构
	const RenderStructRel = (props: any) => {
		const { idx } = props;
		return (
			<div className="trigger-struct">
				{currentRel?.models &&
					currentRel?.models.map((item: any, index: number) => {
						return (
							<div className="structs-list" key={`models_${index}_${item.id}`}>
								{/* 1:图片 2:图标 3:logo 4:纯文字 5:文本框 6:文本域 7:日期 8:复合类型 9:背景图片*/}
								{item.type == 8 ? (
									<div className="structs-child">
										<div className="structs-item-title">{item.title}</div>
										{item?.children.map((val: any, ind: number) => {
											return (
												<div className="structs-item flex justify-between" key={`children_${ind}_${val.id}`}>
													{val.title || val.ph}
													{val.bindId ? (
														<Button size="small" onClick={() => handleUnSelect(val)}>
															取消选中
														</Button>
													) : (
														<Button type="primary" size="small" onClick={() => handleSelect(val, idx)}>
															选中
														</Button>
													)}
												</div>
											);
										})}
									</div>
								) : (
									<div className="structs-item flex justify-between">
										{item.title}
										{item.bindId ? (
											<Button size="small" onClick={() => handleUnSelect(item)}>
												取消选中
											</Button>
										) : (
											<Button type="primary" size="small" onClick={() => handleSelect(item, idx)}>
												选中
											</Button>
										)}
									</div>
								)}
							</div>
						);
					})}
			</div>
		);
	};

	const renderElement = (list: any[]) => {
		return list.map((item, index) => {
			return (
				<div className="element-item flex justify-between items-center" key={`element-${item.id}`}>
					{item.type == 'text' && <div className="item-name">{item.text}</div>}
					{item.type == 'image' && (
						<div className="item-image">
							<img src={cdnUrl + item.text} />
						</div>
					)}
					<Trigger
						popup={() => <RenderStructRel idx={index} />}
						trigger="focus"
						showArrow
						position="lt"
						className="trigger-popup">
						<Button size="small" type={item.bindId ? 'default' : 'primary'}>
							{item.bindId ? '已' : ''}绑定
						</Button>
					</Trigger>
				</div>
			);
		});
	};

	const elements = useMemo(() => {
		const elements = getElementList(slideElement);
		return elements;
	}, [slideElement]);

	const init = async () => {
		let rel: any = {},
			relObj: any = {};

		if (id) {
			rel = await getBindInfo();
			relList[slideId] = rel;
			setRelList({ ...relList });
		} else {
			rel = relList[slideId];
		}
		if (rel) {
			rel?.models.forEach((item: any) => {
				if (item.type == 8) {
					item.children.forEach((val: any) => {
						if (val.bindId) {
							relObj[val.bindId] = val.id;
						}
					});
				} else {
					if (item.bindId) {
						relObj[item.bindId] = item.id;
					}
				}
			});
			elements.forEach((item) => {
				if (relObj[item.id]) {
					item.bindId = relObj[item.id];
				}
			});
			setCurrentRel(rel);
		} else {
			setCurrentRel({});
		}
		setCurElement(elements);
	};

	// 监听绑定关系回调
	useEffect(() => {
		if (curElement) {
			getBindRelation();
		}
	}, [curElement]);

	useEffect(() => {
		if (slideId && slideElement) {
			init();
		}
	}, [slideId, slideElement]);

	useEffect(() => {
		getStyleList();

		// console.log('authStructs', authStructs);
	}, []);

	return (
		<>
			<div className="panel-head">绑定关系</div>
			<div className="panel-content">
				<div className="structs-panel">
					<div className="structs-title flex items-center justify-between">
						<span>版式选择：</span>
						<div className="flex items-center">
							{/* <Popover position="br" trigger="focus" title="请选择版式" content={renderStructList()}>
								<Button>
									{currentRel?.name || '请选择'}
									<IconCaretDown />
								</Button>
							</Popover> */}
							<Trigger
								showArrow
								arrowProps={{
									style: {
										backgroundColor: '#fff',
										boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
									}
								}}
								trigger="click"
								position="br"
								popupVisible={popupVisible}
								onClickOutside={() => setPopupVisible(false)}
								popup={() => (
									<div
										style={{
											padding: '10px',
											width: '300px',
											textAlign: 'center',
											backgroundColor: 'var(--color-bg-popup)',
											boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
										}}>
										{renderStructList()}
									</div>
								)}>
								<Button type="secondary" onClick={() => setPopupVisible(true)}>
									{currentRel?.name || '请选择'}
									<IconCaretDown />
								</Button>
							</Trigger>
						</div>
					</div>

					{/* 目录版式，填写绑定的目录个数 */}
					{/* {currentRel?.id == 2 && <InputNumber placeholder="请输入绑定目录个数" />} */}
					<div className="element-list">{renderElement(curElement)}</div>
				</div>
			</div>
		</>
	);
};
