import fetch from '@/common/fetch';

// 主题列表
export const getThemeList = (param: any) =>
	fetch({
		url: '/res/v1/theme/list',
		params: param,
		method: 'get'
	});

// 添加主题
export const addTheme = (data: any) =>
	fetch({
		url: '/res/v1/theme/',
		data,
		method: 'post'
	});

// 主题修改
export const putThemeEdit = (id: string, data: any) =>
	fetch({
		url: `/res/v1/theme/${id}`,
		data,
		method: 'put'
	});

// 主题删除
export const delTheme = (id: string) =>
	fetch({
		url: `/res/v1/theme/${id}`,
		method: 'delete'
	});

/**
 * 主题色管理
 */
export const getThemeColorList = (param?: any) =>
	fetch({
		url: '/res/v1/theme/color/list',
		params: param,
		method: 'get'
	});
export const getThemeColorSelect = () =>
	fetch({
		url: '/res/v1/theme/color/options',
		method: 'get'
	});
export const postThemeColor = (data: any) =>
	fetch({
		url: '/res/v1/theme/color',
		data,
		method: 'post'
	});
export const putThemeColor = (id: number, data: any) =>
	fetch({
		url: `/res/v1/theme/color/${id}`,
		data,
		method: 'put'
	});
export const delThemeColor = (id: number) =>
	fetch({
		url: `/res/v1/theme/color/${id}`,
		method: 'DELETE'
	});
/**
 * 字体管理
 */
export const getThemeFontList = (param: any) =>
	fetch({
		url: '/res/v1/theme/typeface/list',
		params: param,
		method: 'get'
	});
export const getThemeFontSelect = () =>
	fetch({
		url: '/res/v1/theme/typeface/options',
		method: 'get'
	});
export const postThemeFont = (data: any) =>
	fetch({
		url: '/res/v1/theme/typeface',
		data,
		method: 'post'
	});
export const putThemeFont = (id: number, data: any) =>
	fetch({
		url: `/res/v1/theme/typeface/${id}`,
		data,
		method: 'put'
	});
export const delThemeFont = (id: number) =>
	fetch({
		url: `/res/v1/theme/typeface/${id}`,
		method: 'DELETE'
	});
