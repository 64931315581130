import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Popconfirm } from '@arco-design/web-react';
import { IconPlusCircle } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';
import { getThemeList } from '@/api/theme';

export default () => {
	const { cdnUrl } = useContext(GlobalContext);
	const navigator = useNavigate();

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: '预览图',
			dataIndex: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => <img style={{ width: '80px' }} src={cdnUrl + record.preview} />
		},
		{
			title: '主题名称',
			dataIndex: 'name'
		},
		{
			title: '颜色',
			dataIndex: 'orderNo',
			render: (_: any, record: any) => <>{record.color.name}</>
		},
		{
			title: '字体',
			dataIndex: 'typeface',
			render: (_: any, record: any) => <>{record.typeface.name}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 160,
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={() => {
							navigator(`/theme/edit/${record.id}`, {
								state: record
							});
						}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此版式？" onOk={() => {}}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getThemeList({
			page,
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button type="primary" icon={<IconPlusCircle />} onClick={() => navigator('/theme/add')}>
					新增主题
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
