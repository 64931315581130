import fetch from '@/common/fetch';

export const getUserList = (param: any) =>
	fetch({
		url: '/user/v1/users/list',
		params: param,
		method: 'get'
	});

export const postAccountRecharge = (data: any) =>
	fetch({
		url: '/user/v1/users/account/recharge',
		method: 'post',
		data
	});

export const getUserVipList = () => fetch({ url: '/order/v1/spu/membership/VIP/list', method: 'get' });

export const postUserVip = (data: any) =>
	fetch({
		url: '/user/v1/users/membership/rewards',
		method: 'post',
		data
	});
