import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getStar5(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const { width, height } = data
  // const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5
  // const angle = 36
  // const radian = (angle / 2) * Math.PI / 180
  // const oppositeX = width / Math.cos(radian)
  // const oppositeY = height / Math.cos(radian)
  // const adjacentX = width * Math.tan(radian)
  // const adjacentY = height * Math.tan(radian)
  const radiusX = 0.18
  const radiusY = 0.38
  const radian = Math.atan(width * (0.5 - radiusX) / height)
  const p1 = height * radiusY * Math.tan(radian)
  const p2 = height * (1 - radiusY) * Math.tan(radian)

  return new Polygon([
    { x: width / 2, y: 0 },
    { x: width / 2 + p1, y: height * radiusY },
    { x: width, y: height * radiusY },
    { x: width / 2 + p2, y: height * (1 - radiusY) },
    { x: width * (1 - radiusX), y: height },
    { x: width / 2, y: height / 2 + height * 0.26 },
    { x: width * radiusX, y: height },
    { x: width / 2 - p2, y: height * (1 - radiusY) },
    { x: 0, y: height * radiusY },
    { x: width / 2 - p1, y: height * radiusY },
  ], option)
}