import { forwardRef, useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react';
import { Canvas, CanvasOptions, FabricObject } from 'fabric';

import { SlideData, ViewportSize, Element, Theme, ThemeValue } from '@/types/element';
import useElementList, { UseElementListProps } from './hook/element';
import useCanvas from './hook/canvas';
import { getColorOrGradient } from './helper';

import WatermarkNode from './Element/watermark';

export interface SlideProps {
	data: SlideData;
	layoutList?: SlideData[];
	viewportSize?: ViewportSize;
	onSelect?(data: Element): void;
	editable?: boolean;
	watermark?: boolean;
	scale?: number;
	onRendered?(): void;
	cdnPrefix?: string;
	themeMap?: Theme;
	masterList?: SlideData[];
}

export default forwardRef(function Slide(
	{
		data,
		viewportSize,
		layoutList,
		onSelect,
		editable = true,
		watermark = true,
		scale = 1,
		onRendered,
		cdnPrefix = 'https://cdn.gaippt.com/',
		themeMap,
		masterList
	}: SlideProps,
	ref
) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [select, setSelect] = useState<[Element, FabricObject | undefined] | []>([]);
	const layout = useMemo(() => (layoutList || []).find((item) => item.name == data.layoutRef), [data, layoutList]);
	const elementList = useMemo(
		() => [...(layout?.elements || []).map((item) => ({ ...item, selectable: false })), ...data.elements],
		[data, layout]
	);
	const theme = useMemo(() => {
		if (!masterList || !themeMap || !data.layoutRef) return {};
		for (const master of masterList) {
			if (master.themeName && master.colorMap && master.layouts?.includes(data.layoutRef)) {
				const res: ThemeValue['color'] = { ...themeMap[master.themeName].color };
				const map = themeMap[master.themeName].color;
				Object.entries(master.colorMap).forEach(([key, value]) => {
					if (value && map[value]) res[key] = map[value];
				});
				return res;
			}
		}
		return {};
	}, [data, masterList, themeMap]);

	const canvasOption = useMemo<Partial<CanvasOptions>>(() => {
		const params: Partial<CanvasOptions> = {
			...viewportSize
		};
		const background = data.background || layout?.background;
		if (background) {
			params.backgroundColor = getColorOrGradient(background, {
				width: viewportSize?.width || 1280,
				height: viewportSize?.height || 720,
				theme
			});
		}
		return params;
	}, [viewportSize, data, layout, theme]);
	const canvas = useCanvas({
		canvas: canvasRef.current,
		editable,
		option: canvasOption
	});
	const onSelectEvent = useCallback<NonNullable<UseElementListProps['onSelect']>>((data, event) => {
		setSelect([data, event]);
	}, []);

	const { elements } = useElementList({
		list: elementList,
		onSelect: onSelectEvent,
		cdnPrefix,
		theme
	});

	useEffect(() => {
		if (canvas && editable) {
			const currentCanvas = canvas as Canvas;
			if (select[0]) {
				onSelect?.(select[0]);
			}
			// group中元素选中
			if (select[1]) {
				currentCanvas.setActiveObject(select[1]);
				currentCanvas.renderAll();
			}
		}
	}, [select, canvas, onSelect, editable]);

	useEffect(() => {
		if (canvas && elements.length) {
			elements.forEach((node) => {
				canvas.add(node);
			});

			if (watermark) {
				for (var x = 0; x < canvas.width; x += 200) {
					for (var y = 0; y < canvas.height; y += 200) {
						const watermarkNode = WatermarkNode(x, y);
						canvas.add(watermarkNode);
					}
				}
			}

			canvas.on('after:render', function () {
				console.log('所有对象已渲染完毕');
				onRendered?.();
			});
		}
	}, [canvas, elements, data]);

	useImperativeHandle(ref, () => ({
		canvas,
		canvasRef: canvasRef.current
	}));

	return (
		<div style={{ zoom: scale }}>
			<canvas ref={canvasRef} style={{ border: '1px solid #000000' }} />
		</div>
	);
});
