import { Path, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getSnip1Rect(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? Math.floor(Math.min(width, height) * data.shape.radius) : 15
  const path = `M 0 0 L ${width - radius} 0 L ${width} ${radius} L ${width} ${height} L 0 ${height} Z`

  return new Path(path, option)
}