import { Gradient, TMat2D } from 'fabric';
import Color from 'color';

import { Gradient as GradientData, ColorInfo, ThemeValue } from '@/types/element';

export interface ColorGradientOption {
	width: number;
	height: number;
	offsetX?: number;
	offsetY?: number;
	gradientTransform?: TMat2D;
	theme?: ThemeValue['color'];
}

export function getPptColor(color: ColorInfo, opt: Partial<ColorGradientOption>) {
	const { theme } = opt;
	// 兼容老数据
	if (typeof color == 'string') return color;
	let val: any = '#000000';
	switch (color.type) {
		case 1:
			val = color.value;
			break;
		case 2:
			val = theme?.[color.value]?.value;
			break;
		default:
	}
	let rgb: any = Color(val || '#000000');

	const { mods } = color;
	const { alpha, lumMod = 0, lumOff = 0, satMod = 0, satOff = 0, tint = 0, shade = 0, hueOff = 0, hueMod = 0 } = mods;

	if ('lumMod' in mods || 'lumOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newL = l / 100;
		if (lumMod) {
			newL = newL * lumMod;
		}
		if (lumOff) {
			newL += lumOff;
		}
		rgb = rgb.hsl(h, s, newL * 100);
	}
	if ('hueMod' in mods || 'hueOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newH = h / 100;
		if (hueMod) {
			newH = newH * hueMod;
		}
		if (hueOff) {
			newH += hueOff;
		}
		rgb = rgb.hsl(newH * 100, s, l);
	}
	if ('satMod' in mods || 'satOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newS = s / 100;
		if (satMod) {
			newS = newS * satMod;
		}
		if (satOff) {
			newS += satOff;
		}
		rgb = rgb.hsl(h, newS * 100, l);
	}
	if ('shade' in mods) {
		const s = shade;
		const { r, g, b } = rgb.object();
		rgb = Color.rgb([r * s, g * s, b * s]);
	}
	if ('tint' in mods) {
		const t = tint;
		const { r, g, b } = rgb.object();
		rgb = Color.rgb([r + (255 - r) * t, g + (255 - g) * t, b + (255 - b) * t]);
	}
	const { color: rgbList } = rgb.rgb();

	return `rgba(${rgbList.map((item: number) => Math.round(item)).join(',')},${typeof alpha == 'number' ? alpha : 1})`;
}

export function getLineCoords(
	width: number,
	height: number,
	angle?: number,
	_scaled?: boolean
): { x1: number; y1: number; x2: number; y2: number } {
	if (!angle) {
		return { x1: 0, y1: 0, x2: width, y2: 0 };
	}
	const radian = (angle * Math.PI) / 180;
	const residue = ((Math.abs(90 - angle) % 90) * Math.PI) / 180;
	const radius = Math.abs(Math.sin(residue) * (width + Math.cos(residue) * (height / Math.sin(residue))));
	const xLine = Math.abs(Math.floor(Math.cos(radian) * radius));
	const yLine = Math.abs(Math.floor(Math.sin(radian) * radius));

	if (angle < 90) {
		return { x1: 0, y1: 0, x2: xLine, y2: yLine };
	}
	if (angle == 90) {
		return { x1: 0, y1: 0, x2: 0, y2: height };
	}
	if (angle < 180) {
		return { x1: width, y1: 0, x2: width - xLine, y2: yLine };
	}
	if (angle == 180) {
		return { x1: width, y1: 0, x2: 0, y2: 0 };
	}
	if (angle < 270) {
		// return { x1: 1, y1: 1, x2: 0.6 - Math.abs(Math.cos(radian)), y2: 0.6 - Math.abs(Math.sin(radian)) }
		return { x1: width, y1: height, x2: width - xLine, y2: height - yLine };
	}
	if (angle == 270) {
		return { x1: 0, y1: height, x2: 0, y2: 0 };
	}
	return { x1: 0, y1: height, x2: xLine, y2: height - yLine };
}

export function getRadialCoords(width: number, height: number, data: GradientData) {
	const { fillToRect } = data;
	const { l = 0, t = 0, r = 0, b = 0 } = fillToRect;
	const { l: tl = 0, t: tt = 0, r: tr = 0, b: tb = 0 } = data.tileRect;

	const radius = Math.sqrt(Math.pow(width * (1 - (tl + tr)), 2) + Math.pow(height * (1 - (tt + tb)), 2)) / 2;
	const useRadiusX = 'l' in fillToRect && 'r' in fillToRect && l != r;
	const useRadiusY = 't' in fillToRect && 'b' in fillToRect && t != b;

	return {
		r1: 0,
		r2: radius,
		x1: width * l,
		y1: height * t,
		x2: width * (useRadiusX ? (l - r) / 2 : l || (!tr && !tl ? 0.5 : 0)),
		y2: height * (useRadiusY ? (t - b) / 2 : t || (!tt && !tb ? 0.5 : 0))
	};
}

export function getGradient(data: GradientData, opt: ColorGradientOption) {
	const { offsetX = 0, offsetY = 0, gradientTransform, width, height } = opt || {};
	if (data.type == 'linear') {
		new Gradient({});
	}
	const commonOptions = {
		type: data.type,
		gradientUnits: data.gradientUnits,
		colorStops: data.colorStops.map((item) => ({
			...item,
			color: getPptColor(item.color, opt)
		})),
		offsetX,
		offsetY,
		gradientTransform
	};
	if (data.type == 'radial') {
		return new Gradient({
			...commonOptions,
			coords: getRadialCoords(width, height, data)
		});
	}
	return new Gradient({
		...commonOptions,
		coords: getLineCoords(width, height, data.angle, data.scaled)
	});
}
