import { Path, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getSnip2DiagRect(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.point2 == 'number' ? Math.floor(Math.min(width, height) * data.shape.point2) : 15
  const radius1 = typeof data.shape?.point1 == 'number' ? Math.floor(Math.min(width, height) * data.shape.point1) : 0
  const path = `M ${radius1} 0 L ${width - radius} 0 L ${width} ${radius} L ${width} ${height - radius1} L ${width - radius1} ${height} L ${radius} ${height} L 0 ${height - radius} L 0 ${radius1} Z`

  return new Path(path, option)
}