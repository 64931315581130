import { Path, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getCorner(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const point1 = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.5
  const point2 = typeof data.shape?.point2 == 'number' ? data.shape.point2 : 0.5
  const size1 = Math.min(height, width) * point1
  const size2 = Math.min(height, width) * point2

  const path = `
    M 0 0 
    L ${size2} 0
    L ${size2} ${height - size1}
    L ${width} ${height - size1}
    L ${width} ${height}
    L 0 ${height}
    Z`

  return new Path(path, option)
}