import { Triangle } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getFlowChartMerge(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)

  return new Triangle({
    ...option,
    flipY: !data.flipY,
  })
}