import fetch from '@/common/fetch';
// import { getStorage } from '@/utils'

export interface LoginData {
	user_name: string;
	password: string;
}

export const postLogin = (data: LoginData) =>
	fetch({
		url: '/uaa/oauth/token',
		method: 'post',
		headers: {
			Authorization: 'Basic YWRtaW46MTIzNDU2',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data: {
			grant_type: 'password',
			...data
		}
	});
export const delLogout = () =>
	fetch({
		url: '/uaa/oauth/logout',
		method: 'delete'
	});
export const getUserInfo = () =>
	fetch({
		url: '/admin/v1/users/info',
		method: 'get'
	});
// 刷新token
export const postRefreshToken = (data: any) => {
	return fetch({
		url: '/uaa/oauth/token',
		method: 'post',
		headers: {
			Authorization: 'Basic YWRtaW46MTIzNDU2',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data
	});
};

export const postLoginProd = (data: LoginData) =>
	fetch({
		url: '/uaa/oauth/token',
		method: 'post',
		headers: {
			Authorization: 'Basic YWRtaW46MTIzNDU2',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data: {
			grant_type: 'password',
			...data
		},
		baseURL: 'https://grayscale002.gaippt.com/api'
	});
