import JSZip from 'jszip';

export const parseThemeXML = async (data: any): Promise<any> => {
	const zip: any = await JSZip.loadAsync(data);
	if (zip) {
		const zipStr = await zip.file('theme/theme/theme1.xml').async('string');

		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(zipStr, 'text/xml');

		const color = extractColors(xmlDoc);
		const font = extractFonts(xmlDoc);

		return {
			colors: color,
			fonts: font
		};
	}
};

const extractColors = (xmlDoc: any) => {
	const clrScheme = xmlDoc.getElementsByTagName('a:clrScheme')[0];
	const colors: any = {};

	if (clrScheme) {
		colors['name'] = clrScheme.getAttribute('name');

		const dk1 = clrScheme.getElementsByTagName('a:dk1')[0];
		const lt1 = clrScheme.getElementsByTagName('a:lt1')[0];
		const dk2 = clrScheme.getElementsByTagName('a:dk2')[0];
		const lt2 = clrScheme.getElementsByTagName('a:lt2')[0];
		const accent1 = clrScheme.getElementsByTagName('a:accent1')[0];
		const accent2 = clrScheme.getElementsByTagName('a:accent2')[0];
		const accent3 = clrScheme.getElementsByTagName('a:accent3')[0];
		const accent4 = clrScheme.getElementsByTagName('a:accent4')[0];
		const accent5 = clrScheme.getElementsByTagName('a:accent5')[0];
		const accent6 = clrScheme.getElementsByTagName('a:accent6')[0];
		const hlink = clrScheme.getElementsByTagName('a:hlink')[0];
		const folHlink = clrScheme.getElementsByTagName('a:folHlink')[0];

		if (dk1) {
			const dk1Color = dk1.getElementsByTagName('a:sysClr')[0].getAttribute('lastClr');
			colors['dk1'] = '#' + dk1Color;
		}
		if (lt1) {
			const color = lt1.getElementsByTagName('a:sysClr')[0].getAttribute('lastClr');
			colors['lt1'] = '#' + color;
		}
		if (dk2) {
			const color = dk2.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['dk2'] = '#' + color;
		}
		if (lt2) {
			const color = lt2.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['lt2'] = '#' + color;
		}

		if (accent1) {
			const color = accent1.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent1'] = '#' + color;
		}
		if (accent2) {
			const color = accent2.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent2'] = '#' + color;
		}
		if (accent3) {
			const color = accent3.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent3'] = '#' + color;
		}
		if (accent4) {
			const color = accent4.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent4'] = '#' + color;
		}
		if (accent5) {
			const color = accent5.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent5'] = '#' + color;
		}
		if (accent6) {
			const color = accent6.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['accent6'] = '#' + color;
		}
		if (hlink) {
			const color = hlink.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['hlink'] = '#' + color;
		}
		if (folHlink) {
			const color = folHlink.getElementsByTagName('a:srgbClr')[0].getAttribute('val');
			colors['folHlink'] = '#' + color;
		}
		return colors;
	} else {
		return null;
	}
};

const extractFonts = (xmlDoc: any) => {
	const fontScheme = xmlDoc.getElementsByTagName('a:fontScheme')[0];
	const fonts: any = {
		name: '',
		eaTit: '',
		eaTxt: '',
		latinTit: '',
		latinTxt: ''
	};

	if (fontScheme) {
		fonts['name'] = fontScheme.getAttribute('name');

		const majorFont = fontScheme.getElementsByTagName('a:majorFont')[0];
		const minorFont = fontScheme.getElementsByTagName('a:minorFont')[0];

		// 标题
		if (majorFont) {
			fonts['latinTit'] = majorFont.getElementsByTagName('a:latin')[0].getAttribute('typeface');
			fonts['eaTit'] = majorFont.getElementsByTagName('a:ea')[0].getAttribute('typeface');
		}
		// 正文
		if (minorFont) {
			fonts['latinTxt'] = minorFont.getElementsByTagName('a:latin')[0].getAttribute('typeface');
			fonts['eaTxt'] = minorFont.getElementsByTagName('a:ea')[0].getAttribute('typeface');
		}

		return fonts;
	} else {
		return null;
	}
};
