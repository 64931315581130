import { Rect, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getRoundRect(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  option.width = data.width
  option.height = data.height
  const radius = typeof data.shape?.radius == 'number' ? Math.floor(Math.min(data.width, data.height) * data.shape.radius) : 15
  option.rx = radius
  option.ry = radius

  return new Rect(option)
}