import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Form, Input, Button, Grid, Message, Select } from '@arco-design/web-react';
import { GlobalContext } from '@/hooks/context';

import { postThemeFont, putThemeFont } from '@/api/theme';
import { getFontSelect } from '@/api/fonts';

export default () => {
	const navigator = useNavigate();
	const { cdnUrl } = useContext(GlobalContext);

	const { id }: any = useParams();
	const { state } = useLocation();

	const [form] = Form.useForm();
	const Row = Grid.Row;
	const Col = Grid.Col;

	const [loading, setLoading] = useState<boolean>(false);
	const [enFontList, setEnFontList] = useState<any>([]);
	const [cnFontList, setCnFontList] = useState<any>([]);

	// 提交
	const handleSave = async (values: any) => {
		setLoading(true);
		try {
			if (id) {
				await putThemeFont(id, values);
				Message.success('编辑成功');
			} else {
				await postThemeFont(values);
				Message.success('添加成功');
			}
			setLoading(false);
			navigator(-1);
		} catch (error) {
			setLoading(false);
			Message.error('提交失败');
		}
	};

	const getFontList = async (lang: string) => {
		let res = await getFontSelect(lang);
		if (res.code == 200) {
			if (lang == 'en') {
				setEnFontList(res.data);
			}
			if (lang == 'cn') {
				setCnFontList(res.data);
			}
		}
	};

	const renderEnFont = () => {
		return enFontList.map((item: any) => {
			return (
				<Select.Option key={item.value} value={item.value}>
					<div
						style={{
							width: '115px',
							height: '25px',
							backgroundSize: 'cover',
							backgroundImage: `url(${cdnUrl + item.label}?v=6)`
						}}></div>
				</Select.Option>
			);
		});
	};
	const renderCnFont = () => {
		return cnFontList.map((item: any) => {
			return (
				<Select.Option key={item.value} value={item.value}>
					<div
						style={{
							width: '115px',
							height: '25px',
							backgroundSize: 'cover',
							backgroundImage: `url(${cdnUrl + item.label}?v=6)`
						}}></div>
				</Select.Option>
			);
		});
	};

	// 获取详情
	useEffect(() => {
		if (id && state) {
			form.setFieldsValue({
				name: state.name,
				eaTit: state.eaTit.id,
				eaTxt: state.eaTxt.id,
				latinTit: state.latinTit.id,
				latinTxt: state.latinTxt.id
			});
		}
	}, [id, state]);

	useEffect(() => {
		getFontList('cn');
		getFontList('en');
	}, []);

	return (
		<div className="page-warp">
			<div className="page-warp-title">{id ? '编辑' : '新增'}字体</div>
			<Form form={form} style={{ maxWidth: 650 }} onSubmit={handleSave}>
				<Form.Item label="字体名称" field="name" rules={[{ required: true, message: '请输入字体名称' }]}>
					<Input placeholder="请输入字体名称" />
				</Form.Item>

				<Form.Item label="中文字体">
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item label="标题" field="eaTit" rules={[{ required: true, message: '请选择中文标题字体' }]}>
								<Select placeholder="请选择中文标题字体">{renderCnFont()}</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="正文" field="eaTxt" rules={[{ required: true, message: '请选择中文正文字体' }]}>
								<Select placeholder="请选择中文正文字体">{renderCnFont()}</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item label="西文字体">
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item label="标题" field="latinTit" rules={[{ required: true, message: '请选择西文标题字体' }]}>
								<Select placeholder="请选择西文标题字体">{renderEnFont()}</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="正文" field="latinTxt" rules={[{ required: true, message: '请选择西文正文字体' }]}>
								<Select placeholder="请选择西文正文字体">{renderEnFont()}</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 5 }}>
					<Button type="primary" htmlType="submit" loading={loading} style={{ marginRight: 24 }}>
						提交
					</Button>
					<Button
						style={{ marginRight: 24 }}
						onClick={() => {
							form.resetFields();
						}}>
						重置
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
