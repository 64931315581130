import { FabricObjectProps, Pattern, Shadow, TFiller } from 'fabric'

import { Element, ColorData, ThemeValue } from '@/types/element'
import { getGradient, ColorGradientOption, getPptColor } from './gradient'

export { getPptColor }

export function getColorOrGradient(color: NonNullable<ColorData>, opt: ColorGradientOption) {
	// 兼容老数据
	if (typeof color == 'string') return color
	if (typeof color == 'object') {
		if ('value' in color) {
			return getPptColor(color, opt)
		}
		return getGradient(color, opt) as TFiller
	}

	return color as string
}

export async function getBaseCommonProps(data: Element, opt?: { theme?: ThemeValue['color'] }) {
	const option: Partial<FabricObjectProps> = {
		top: data.top,
		left: data.left,
		width: data.width,
		height: data.height,
		fill: 'transparent',
		flipX: data.flipX,
		flipY: data.flipY
	}
	if (data.border?.color) {
		option.stroke = getColorOrGradient(data.border.color, {
			width: data.width,
			height: data.height,
			theme: opt?.theme
		})
		option.strokeWidth = data.border.width
		option.strokeMiterLimit = data.border.miterLimit || 0
		option.strokeUniform = true
		if (data.border.style == 'dot') {
			const dot = Math.max(data.border?.width || 1, 2)
			option.strokeDashArray = [dot, dot]
		}
		if (data.border.style == 'dash') {
			const dot = Math.max(data.border?.width || 1, 2)
			option.strokeDashArray = [dot * 3.2, dot]
		}
	}
	if (data.background) {
		option.fill = getColorOrGradient(data.background, {
			width: data.width,
			height: data.height,
			theme: opt?.theme
		})
	}
	if (data.backgroundImage) {
		const img = new Image()
		img.crossOrigin = 'anonymous'
		img.src = `https://cdn.gaippt.com/${data.backgroundImage}`
		await new Promise((resolve) => {
			img.onload = () => resolve(true)
		})
		const canvas = document.createElement('canvas')
		canvas.height = data.height
		canvas.width = data.width
		const ctx = canvas.getContext('2d')
		const { left = 0, top = 0, right = 0, bottom = 0 } = data.backgroundImageRect || {}
		ctx?.drawImage(
			img,
			data.width * left,
			data.height * top,
			data.width * (1 - left - right),
			data.height * (1 - top - bottom)
		)

		option.fill = new Pattern({
			crossOrigin: 'anonymous',
			source: canvas,
			repeat: 'no-repeat'
		})
	}
	if (data.shadow) {
		const shadow = new Shadow({
			...data.shadow,
			color: getPptColor(data.shadow.color, { theme: opt?.theme })
		})
		option.shadow = shadow
	}
	return option
}
