import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Popconfirm, ColorPicker, Space } from '@arco-design/web-react';
import { IconPlusCircle } from '@arco-design/web-react/icon';

import { getThemeColorList } from '@/api/theme';

import { formatDate } from '@/utils';

export default () => {
	const navigator = useNavigate();
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: '名称',
			dataIndex: 'name'
		},
		{
			title: '文字/背景',
			render: (_: any, record: any) => (
				<Space>
					<ColorPicker defaultValue={record.dk1} disabled />
					<ColorPicker defaultValue={record.lt1} disabled />
					<ColorPicker defaultValue={record.dk2} disabled />
					<ColorPicker defaultValue={record.lt2} disabled />
				</Space>
			)
		},
		{
			title: '主题色/着色',
			render: (_: any, record: any) => (
				<Space>
					<ColorPicker defaultValue={record.accent1} disabled />
					<ColorPicker defaultValue={record.accent2} disabled />
					<ColorPicker defaultValue={record.accent3} disabled />
					<ColorPicker defaultValue={record.accent4} disabled />
					<ColorPicker defaultValue={record.accent5} disabled />
					<ColorPicker defaultValue={record.accent6} disabled />
				</Space>
			)
		},
		{
			title: '超链接',
			render: (_: any, record: any) => (
				<Space>
					<ColorPicker defaultValue={record.hlink} disabled />
					<ColorPicker defaultValue={record.folHlink} disabled />
				</Space>
			)
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			render: (_: any, record: any) => <>{record.createTime ? formatDate(record.createTime) : '-'}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 160,
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={() => {
							navigator(`/theme/colors/edit/${record.id}`, {
								state: record
							});
						}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此版式？" onOk={() => {}}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getThemeColorList({
			page,
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button type="primary" icon={<IconPlusCircle />} onClick={() => navigator('/theme/colors/add')}>
					新增主题色
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
