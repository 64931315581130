import { Path, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getMathPlus(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const paddingRadio = 0.15
  const paddingX = width * paddingRadio
  const paddingY = height * paddingRadio
  const radius = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.24
  const plusSize = Math.min(width, height) * Math.min(radius, 1 - paddingRadio * 2) || 1
  const path = `
    M ${paddingX} ${height / 2 - plusSize / 2} 
    L ${width / 2 - plusSize / 2} ${height / 2 - plusSize / 2} 
    L ${width / 2 - plusSize / 2} ${paddingY} 
    L ${width / 2 + plusSize / 2} ${paddingY} 
    L ${width / 2 + plusSize / 2} ${height / 2 - plusSize / 2} 
    L ${width - paddingX} ${height / 2 - plusSize / 2} 
    L ${width - paddingX} ${height / 2 + plusSize / 2} 
    L ${width / 2 + plusSize / 2} ${height / 2 + plusSize / 2} 
    L ${width / 2 + plusSize / 2} ${height - paddingY} 
    L ${width / 2 - plusSize / 2} ${height - paddingY} 
    L ${width / 2 - plusSize / 2} ${height / 2 + plusSize / 2} 
    L ${paddingX} ${height / 2 + plusSize / 2} 
    Z`

  return new Path(path, option)
}