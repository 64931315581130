import { Textbox } from 'fabric';

export default (left: number, top: number, text?: string) => {
	const textNode = new Textbox(text || 'GaiPPT', {
		left: left,
		top: top,
		fontSize: 30,
		fill: 'rgba(0, 0, 0, 0.05)', // 设置水印颜色为半透明
		selectable: false, // 禁止选择水印
		evented: false, // 禁止事件交互
		angle: -20 // 水印倾斜角度
	});
	return textNode;
};
