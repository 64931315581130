import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getHomePlate(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const width = data.width
  const height = data.height

  return new Polygon([
    { x: 0, y: 0 },
    { x: width - height / 2, y: 0 },
    { x: width, y: height / 2 },
    { x: width - height / 2, y: height },
    { x: 0, y: height },
  ], option)
}