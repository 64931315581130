import { Textbox, Group, FabricObject, Path, TextboxProps } from 'fabric'

import { TextData } from '@/types/element'
import { getShape, getTextTop, getTextStyle } from '../helper'
import { GetElementOption } from '../types'

export default async function getText(data: TextData, { theme }: GetElementOption) {
  let sumHeight = 0
  const borderWidth = data.border?.width || 0
  const { left: lInt = 9.5, right: rInt = 9.5, top: tInt = 5, bottom: bInt = 5 } = data.margin
  const mainWidth = data.width - lInt - rInt
  const mainHeight = data.height - tInt - bInt - borderWidth

  if (data.paragraphType) return null

  const texts = data.text.map((line) => {
    if (!line.text) return null
    const { styles } = getTextStyle(line.text, {
      width: data.width,
      height: data.height,
      theme,
    })
    const option: Partial<TextboxProps> = {
      textAlign: line.align,
      fontFamily: '思源黑体',
      splitByGrapheme: data.noAutoWrap == false || data.wrap == 'square',
      styles,
      // fontSize: maxFontSize,
      lineHeight: line.lineHeight,
    }
    if (data.layout == 'horizontal') {
      option.width = mainWidth
      option.lockScalingX = true
    } else {
      option.height = mainHeight
      option.lockScalingY = true
    }

    const text = new Textbox(line.text.map(it => it.text).join(''), option)

    const spaceBefore = Math.ceil(text.height * (line.spaceBefore || 0))

    text.set({ top: sumHeight + spaceBefore })

    sumHeight += text.textLines.length > 1 ? text.height : text.height * line.lineHeight + spaceBefore

    return text
  }).filter(item => !!item) as Textbox[]

  const textGroup = new Group(texts, {
    left: borderWidth,
  })

  texts.forEach((item, index) => {
    const line = data.text[index]
    if (line.indentLevel) {
      item.set({ left: -(item.width / 2) + 50 * line.indentLevel })
    }
  })

  const nodes: FabricObject[] = [textGroup]

  let shape: Group | null = null
  if (data.shape && (data.border || data.background || data.backgroundImage)) {
    
    const node = await getShape(data.shape.type, {
      ...data, 
      top: 0, 
      left: 0, 
      flipX: false,
      flipY: false,
    }, { theme })
    if (node) {
      shape = new Group([node], {
        flipX: (!data.flipY && data.flipX) || (data.flipY && !data.flipX),
      })
      shape.set({ width: data.width, height: data.height })
      if (data.shape.type == 'path') {
        const n = node as Group
        n.getObjects().forEach((it) => {
          const _n = it as Path
          _n.set({ left: _n.pathOffset.x - (_n.width + data.width) / 2, top: _n.pathOffset.y - (_n.height + data.height) / 2 })
        })
      }
    }
  }

  if (shape) nodes.unshift(shape)
  const group = new Group(nodes, {
    left: data.left,
    top: data.top,
    flipY: data.flipY,
    flipX: data.flipY || (data.flipX && data.flipY),
    // centeredRotation: true,
  })

  const paddingMap = {
    bottom: -bInt - borderWidth / 2,
    center: 0,
    top: tInt + borderWidth / 2
  }
  const vertical = data.layout == 'horizontal' && data.autoFit ? 'center' : data.vertical
  
  group.set({ width: data.width, height: Math.max(data.height, sumHeight) })

  textGroup.set({
    top: (getTextTop(vertical, data.height, textGroup.height) + paddingMap[vertical] - data.height / 2),
    left: lInt - data.width / 2,
  })
  
  shape && shape.set({ top: -data.height / 2, left: -data.width / 2 })
  

  if (data.rot) {
    group.rotate(data.rot)
  }

  return group
}
