import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getTriangle(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5

  return new Polygon([
    { x: width * radius, y: 0 },
    { x: width, y: height },
    { x: 0, y: height }
  ], option)
}