import { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, Space, InputNumber, Message, Radio } from '@arco-design/web-react';

import { postAccountRecharge } from '@/api/user';

interface IProps {
	visible: boolean;
	info: any;
	onClose: () => void;
	onOk: () => void;
}

export default (props: IProps) => {
	const { visible, info, onClose, onOk } = props;

	const FormItem = Form.Item;
	const [form] = Form.useForm();
	const RadioGroup = Radio.Group;

	const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const value = await form.validate();
			console.log('value', value);

			const res = await postAccountRecharge({
				userId: value.id,
				account: value.account,
				quantity: value.quantity,
				remark: value.remark
			});
			setLoading(false);
			if (res.code == 200) {
				Message.success('赠送成功');
				onOk();
				onClose();
			}
		} catch (error) {
			setLoading(false);
			console.log('error', error);
			// Message.error()
		}
	};

	useEffect(() => {
		if (visible && info) {
			form.setFieldsValue({
				id: info.id,
				nickName: info.nickName,
				account: 'diamond'
			});
		} else {
			form.resetFields();
		}
	}, [visible, info]);

	return (
		<Modal title="赠送钻石" style={{ width: 360 }} footer={null} onCancel={onClose} visible={visible}>
			<Form form={form} autoComplete="off" layout="vertical">
				<FormItem label="用户ID" field="id">
					<Input disabled placeholder="please enter your name" />
				</FormItem>
				<FormItem label="用户昵称" field="nickName">
					<Input disabled placeholder="please enter your name" />
				</FormItem>
				<FormItem label="赠送类型" field="account" rules={[{ required: true, message: '请选择赠送类型' }]}>
					<RadioGroup>
						<Radio value="diamond">钻石</Radio>
						<Radio value="pull">下载次数</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem
					label="赠送数量"
					field="quantity"
					rules={[
						{ required: true, message: '请输入赠送数量' },
						{ type: 'number', min: 1, message: '请输入大于 0 的数字' }
					]}>
					<InputNumber placeholder="请输入赠送数量" min={0} />
				</FormItem>
				<FormItem label="备注说明" field="remark" rules={[{ required: true, message: '请选择会员' }]}>
					<Input.TextArea placeholder="请填写备注说明" wrapperStyle={{ width: 300 }} />
				</FormItem>
				<FormItem>
					<Space>
						<Button type="primary" loading={loading} onClick={handleSubmit}>
							提交
						</Button>
						<Button type="secondary" onClick={onClose}>
							取消
						</Button>
					</Space>
				</FormItem>
			</Form>
		</Modal>
	);
};
