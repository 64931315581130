import { Polygon } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getHexagon(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option = await getBaseCommonProps(data, opt)
  const { height, width } = data
  const yRadius = height / 2
  const xRadius = Math.min(height, width) * (typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25)

  return new Polygon([
    {
      x: 0,
      y: yRadius
    },
    {
      x: xRadius,
      y: 0,
    },
    {
      x: width - xRadius,
      y: 0
    },
    {
      x: width,
      y: yRadius
    },
    {
      x: width - xRadius,
      y: height,
    },
    {
      x: xRadius,
      y: height,
    }
  ], option)
}