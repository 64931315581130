import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, Grid, Message } from '@arco-design/web-react';
import { IconImage } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';
import uploadFile, { typeEnum } from '@/utils/uploadFile';

import { getThemeColorSelect, getThemeFontSelect, addTheme, putThemeEdit } from '@/api/theme';

import './index.less';

export default () => {
	const navigator = useNavigate();
	const { cdnUrl } = useContext(GlobalContext);

	const { id }: any = useParams();
	const { state } = useLocation();

	const [form] = Form.useForm();
	const Row = Grid.Row;
	const Col = Grid.Col;

	const [loading, setLoading] = useState<boolean>(false);
	const [colorsList, setColorsList] = useState<any>([]);
	const [fontsList, setFontsList] = useState<any>([]);
	const [curfont, setCurFont] = useState<any>(null);

	const preview = Form.useWatch('preview', form);

	// 提交
	const handleSave = async (values: any) => {
		setLoading(true);
		try {
			if (id) {
				await putThemeEdit(id, values);
				Message.success('编辑成功');
			} else {
				await addTheme(values);
				Message.success('添加成功');
			}
			setLoading(false);
			navigator(-1);
		} catch (error) {
			setLoading(false);
			Message.error('提交失败');
		}
	};

	const handleUpPreview = async (e: Event) => {
		try {
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			if (file) {
				const info: any = await uploadFile(file, typeEnum.preview);
				form.setFieldsValue({
					preview: info.data.key
				});
			}
		} catch (error: any) {
			console.log(error);
			Message.error(error?.msg || error);
		}
	};

	const onFontChange = (val: number) => {
		fontsList.forEach((item: any) => {
			if (item.id == val) {
				setCurFont(item);
			}
		});
	};

	const getColorsList = async () => {
		const res = await getThemeColorSelect();
		setColorsList(res.data);
	};
	const getFontsList = async () => {
		const res = await getThemeFontSelect();
		setFontsList(res.data);
		if (state) {
			fontsList.forEach((item: any) => {
				if (item.id == state.typeface.id) {
					setCurFont(item);
				}
			});
		}
	};

	// 获取详情
	useEffect(() => {
		if (id && state) {
			form.setFieldsValue({
				name: state.name,
				preview: state.preview,
				typeface: state.typeface.id,
				color: state.color.id
			});
		}
	}, [id, state]);

	useEffect(() => {
		getColorsList();
		getFontsList();
	}, []);

	return (
		<div className="page-warp">
			<div className="page-warp-title">{id ? '编辑' : '新增'}主题</div>
			<Form form={form} size="large" style={{ maxWidth: 650 }} onSubmit={handleSave}>
				<Form.Item label="主题名称" field="name" rules={[{ required: true, message: '请输入主题名称' }]}>
					<Input placeholder="请输入主题名称" />
				</Form.Item>

				<Form.Item label="焦点图片" field="preview" rules={[{ required: true, message: '请上传焦点图片' }]}>
					<div className="theme-preview">
						{preview ? (
							<img src={cdnUrl + preview + '?imageView2/1/w/640/h/360'} />
						) : (
							<span>
								<IconImage style={{ fontSize: '40px' }} />
								<p>上传焦点图</p>
							</span>
						)}
						<input className="upload-file" type="file" accept="image/*" onChange={(e: any) => handleUpPreview(e)} />
					</div>
				</Form.Item>
				<Form.Item label="选择字体" field="typeface" rules={[{ required: true, message: '请选择字体' }]}>
					<Select placeholder="请选择字体" onChange={onFontChange}>
						{fontsList.map((item: any) => (
							<Select.Option key={item.id} value={item.id}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				{curfont && (
					<Form.Item wrapperCol={{ offset: 5 }}>
						<div>
							<Row gutter={8}>
								<Col span={12}>
									<div className="flex align-center">
										中文标题：
										<span
											style={{
												display: 'inline-block',
												width: '115px',
												height: '25px',
												backgroundSize: 'cover',
												backgroundImage: `url(${cdnUrl + curfont.eaTit.preview}?v=6)`
											}}
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="flex align-center">
										西文标题：
										<span
											style={{
												display: 'inline-block',
												width: '115px',
												height: '25px',
												backgroundSize: 'cover',
												backgroundImage: `url(${cdnUrl + curfont.latinTit.preview}?v=6)`
											}}
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="flex align-center">
										中文正文：
										<span
											style={{
												display: 'inline-block',
												width: '115px',
												height: '25px',
												backgroundSize: 'cover',
												backgroundImage: `url(${cdnUrl + curfont.eaTxt.preview}?v=6)`
											}}
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="flex align-center">
										西文正文：
										<span
											style={{
												display: 'inline-block',
												width: '115px',
												height: '25px',
												backgroundSize: 'cover',
												backgroundImage: `url(${cdnUrl + curfont.latinTxt.preview}?v=6)`
											}}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</Form.Item>
				)}

				<Form.Item label="选择颜色" field="color" rules={[{ required: true, message: '请选择颜色' }]}>
					<Select placeholder="请选择颜色">
						{colorsList.map((item: any) => (
							<Select.Option key={item.id} value={item.id}>
								<div className="flex align-center">
									<div className="theme-color flex align-center">
										{item.dklt.map((val: string) => (
											<span style={{ backgroundColor: val }}></span>
										))}
									</div>
									<div className="theme-color flex align-center">
										{item.accent.map((val: string) => (
											<span style={{ backgroundColor: val }}></span>
										))}
									</div>
									<div className="theme-color flex align-center">
										{item.link.map((val: string) => (
											<span style={{ backgroundColor: val }}></span>
										))}
									</div>
									<span className="theme-color-name">{item.name}</span>
								</div>
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item wrapperCol={{ offset: 5 }}>
					<Button type="primary" htmlType="submit" loading={loading} style={{ marginRight: 24 }}>
						提交
					</Button>
					<Button
						style={{ marginRight: 24 }}
						onClick={() => {
							form.resetFields();
						}}>
						重置
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
