import { Rect, RectProps } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getFlowChartTerminator(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  option.width = data.width
  option.height = data.height
  const radius = data.height * 0.5
  option.rx = radius

  return new Rect(option)
}