import axios from 'axios';

export default () => {
	const onSave = async (e: any) => {
		console.log('e', e);
		const file = e.target.files[0];
		const filename = file.name;

		const res = await axios.get('http://127.0.0.1:8000/get_post_signature_for_oss_upload');

		const formData = new FormData();
		formData.append('name', filename);
		formData.append('policy', res.data.policy);
		formData.append('OSSAccessKeyId', res.data.ossAccessKeyId);
		formData.append('success_action_status', '200');
		formData.append('signature', res.data.signature);
		formData.append('key', res.data.dir + filename);
		formData.append('file', file);

		const result = await axios.post(res.data.host, formData);

		console.log('result', result);

		if (result.status == 200) {
			console.log('上传成功');
		} else {
			console.log('上传失败', result);
		}
	};

	return (
		<div>
			<input type="file" onChange={onSave} />
		</div>
	);
};
