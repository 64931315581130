import { TextProps } from 'fabric';

import { Vertical, TextLineData, ThemeValue } from '@/types/element';
import { getColorOrGradient } from './base';

export function getTextTop(vertical: Vertical, height: number, textHeight: number) {
	if (vertical == 'center') {
		return (height - textHeight) / 2;
	}

	if (vertical == 'bottom') {
		return height - textHeight;
	}
	return 0;
}

export function getTextStyle(
	text: TextLineData['text'],
	{ theme, width, height }: { width: number; height: number; theme?: ThemeValue['color'] }
) {
	let maxFontSize = 0;
	const styles = {
		0: {
			...text.reduce((total, cur) => {
				const tLen = Object.keys(total).length;
				const obj = (cur.text || '').split('').reduce((t, _c, i) => {
					const fSize = Math.floor((cur.fontSize || 18) / 0.75);
					maxFontSize = Math.max(maxFontSize, fSize);
					const style: Partial<TextProps> = {
						fontSize: fSize,
						fill: 'transparent',
						fontWeight: cur.fontWeight,
						fontStyle: cur.fontStyle,
						underline: cur.underline,
						linethrough: cur.lineThrough
					};
					// 兼容旧数据，fontFamily已废弃
					if (cur.fontFamily) style.fontFamily = cur.fontFamily;
					if (cur.lang == 'zh-CN') {
						if (cur.eaTypeface) style.fontFamily = cur.eaTypeface;
					} else {
						if (cur.latinTypeface) style.fontFamily = cur.latinTypeface;
					}
					if (cur.color)
						style.fill = getColorOrGradient(cur.color, {
							width: width,
							height: height,
							theme
						});
					if (cur.border?.color) {
						style.stroke = getColorOrGradient(cur.border.color, {
							width: width,
							height: height,
							theme
						});
						style.strokeWidth = 1;
					}
					return {
						...t,
						[tLen + i]: style
					};
				}, {});

				return {
					...total,
					...obj
				};
			}, {})
		}
	};

	return { styles, maxFontSize };
}
