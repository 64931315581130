import { Message, Modal } from '@arco-design/web-react';
import axios, { AxiosResponse } from 'axios';
import { getCookies, setCookies, setSessionStorage, getSessionStorage, delSessionStorage, delCookies } from '@/utils';

declare module 'axios' {
	interface AxiosResponse<T = any> {
		code: number;
		msg: string;
	}
}

const baseURL = import.meta.env.VITE_APP_FETCH_BASEURL;
const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;
const RefTokenName = import.meta.env.VITE_APP_REFRESH_TOKEN_NAME;

// 保存是否正在刷新的状态
let isRefreshing = false;
// 保存挂起的请求（即在刷新 token 后需要重新发送的请求）
let pendingRequests: any[] = [];

let isShowMsg = false;

const instance = axios.create({
	baseURL: baseURL,
	timeout: 60000
});

// 请求拦截器
instance.interceptors.request.use(
	(config: any) => {
		if (config.headers['Authorization']) {
		} else {
			let token = getCookies(tokenName);
			if (token) {
				config.headers = {
					...config.headers,
					Authorization: `Bearer ${token}`
				};
			}
		}
		return config;
	},
	(error) => {
		error.data = {};
		error.data.msg = '服务器异常，请联系管理员！';
		return Promise.resolve(error);
	}
);

// 响应拦截器
instance.interceptors.response.use(
	(response: AxiosResponse) => {
		const status = response.status;
		if (status < 200 || status >= 300) {
			response.data.msg = '服务器异常，请联系管理员！';

			if (!isShowMsg) {
				isShowMsg = true;
				Message.error({
					content: '服务器异常，请联系管理员！',
					onClose: () => {
						isShowMsg = false;
					}
				});
			}
		}
		return response.data;
	},
	(error: any) => {
		const originalRequest = error.config;
		const refreshToken = getSessionStorage(RefTokenName);

		if (error.response.status === 401 && !originalRequest._retry) {
			// 没有刷新 token 直接返回登录
			if (!refreshToken) {
				if (!isShowMsg) {
					isShowMsg = true;
					let msg = '登录过期，请重新登录';
					if (error?.response?.data?.msg) {
						msg = error?.response?.data?.msg;
					}

					Modal.error({
						title: '提示',
						style: {
							width: '300px'
						},
						content: msg,
						onOk: () => {
							window.location.href = '/login';
						}
					});
					// Message.error({
					// 	content: '登录过期，请重新登录',
					// 	onClose: () => {
					// 		isShowMsg = false;
					// 	}
					// });
				}

				delCookies(tokenName);
				delSessionStorage(RefTokenName);

				return Promise.reject(error);
			}

			if (isRefreshing) {
				// 如果正在刷新 token，将请求添加到 pendingRequests 中
				return new Promise((resolve) => {
					pendingRequests.push(() => {
						originalRequest.headers['Authorization'] = 'Bearer ' + getCookies(tokenName);
						resolve(instance(originalRequest));
					});
				});
			}
			originalRequest._retry = true;
			isRefreshing = true;

			return new Promise((resolve, reject) => {
				axios
					.post(
						`${baseURL}/uaa/oauth/token`,
						{
							grant_type: 'refresh_token',
							refresh_token: refreshToken
						},
						{
							headers: {
								Authorization: 'Basic YWRtaW46MTIzNDU2',
								'Content-Type': 'application/x-www-form-urlencoded'
							}
						}
					)
					.then((result: any) => {
						if (result.data.code == 200) {
							const { data } = result.data;
							// 设置新的 token
							setCookies(tokenName, data.access_token, data.expires_in);
							// 设置刷新 token，存入 storage
							setSessionStorage(RefTokenName, data.refresh_token);

							originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
							// 执行挂起的请求
							pendingRequests.forEach((callback) => callback());
							pendingRequests = [];
							resolve(instance(originalRequest));
						} else {
							console.log('2222');
							delCookies(tokenName);
							delSessionStorage(RefTokenName);
							if (!isShowMsg) {
								isShowMsg = true;
								Message.error({
									content: '登录过期，请重新登录',
									onClose: () => {
										isShowMsg = false;
									}
								});
							}
							window.location.href = '/login';
							reject();
						}
					})
					.catch(() => {
						delCookies(tokenName);
						delSessionStorage(RefTokenName);
						if (!isShowMsg) {
							isShowMsg = true;
							Modal.error({
								title: '提示',
								content: '登录过期，请重新登录',
								onOk: () => {
									window.location.href = '/login';
								}
							});
						}
						// window.location.href = '/login';
						reject();
					})
					.finally(() => {
						console.log('432143214321-3');
						isRefreshing = false;
					});
			});
		} else {
			console.log('22222222', error);
			let msg = '请求超时或服务器异常，请检查网络或联系管理员！';
			if (error?.response?.data?.msg) {
				msg = error?.response?.data?.msg;
			}
			error.message = msg;
			if (!isShowMsg) {
				isShowMsg = true;

				Modal.error({
					title: '提示',
					content: msg,
					onOk: () => {
						// window.location.reload();
					}
				});

				// Message.error({
				// 	content: msg,
				// 	onClose: () => {
				// 		isShowMsg = false;
				// 	}
				// });
			}
			return Promise.reject(error.response);
		}
	}
);

export default instance;
