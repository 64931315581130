import { Path, RectProps } from 'fabric';

import { Element, ThemeValue } from '@/types/element';
import { getBaseCommonProps } from '../base';

export default async function getWedgeRectCallout(data: Element, opt: { theme?: ThemeValue['color'] }) {
	const option: Partial<RectProps> = await getBaseCommonProps(data, opt);
	const { width, height } = data;
	// const point1 = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.25
	// const point2 = typeof data.shape?.point1 == 'number' ? data.shape.point2 : 0.25

	const path = `
    M 0 0 
    L ${width} 0 
    L ${width} ${height} 
    L 0 ${height}
    Z`;

	return new Path(path, option);
}
