import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Popconfirm } from '@arco-design/web-react';
import { IconPlusCircle } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';
import { getThemeFontList } from '@/api/theme';

import { formatDate } from '@/utils';

export default () => {
	const navigator = useNavigate();
	const { cdnUrl } = useContext(GlobalContext);
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: '名称',
			dataIndex: 'name'
		},
		{
			title: '中文字体',
			children: [
				{
					title: '标题',
					dataIndex: 'eaTit',
					render: (_: any, record: any) => (
						<>
							<div
								style={{
									width: '115px',
									height: '25px',
									backgroundSize: 'cover',
									backgroundImage: `url(${cdnUrl + record.eaTit.preview}?v=6)`
								}}></div>
						</>
					)
				},
				{
					title: '正文',
					dataIndex: 'eaTxt',
					render: (_: any, record: any) => (
						<>
							<div
								style={{
									width: '115px',
									height: '25px',
									backgroundSize: 'cover',
									backgroundImage: `url(${cdnUrl + record.eaTxt.preview}?v=6)`
								}}></div>
						</>
					)
				}
			]
		},
		{
			title: '西文字体',
			children: [
				{
					title: '标题',
					dataIndex: 'latinTit',
					render: (_: any, record: any) => (
						<>
							<div
								style={{
									width: '115px',
									height: '25px',
									backgroundSize: 'cover',
									backgroundImage: `url(${cdnUrl + record.latinTit.preview}?v=6)`
								}}></div>
						</>
					)
				},
				{
					title: '正文',
					dataIndex: 'latinTxt',
					render: (_: any, record: any) => (
						<>
							<div
								style={{
									width: '115px',
									height: '25px',
									backgroundSize: 'cover',
									backgroundImage: `url(${cdnUrl + record.latinTxt.preview}?v=6)`
								}}></div>
						</>
					)
				}
			]
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			render: (_: any, record: any) => <>{record.createTime ? formatDate(record.createTime) : '-'}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 160,
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={() => {
							navigator(`/theme/fonts/edit/${record.id}`, {
								state: record
							});
						}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此版式？" onOk={() => {}}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getThemeFontList({
			page,
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button
					type="primary"
					icon={<IconPlusCircle />}
					onClick={() => {
						navigator(`/theme/fonts/add`);
					}}>
					新增字体
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
