import { Path, RectProps, Rect } from 'fabric'

import { Element, ThemeValue } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getDiagStripe(data: Element, opt: { theme?: ThemeValue['color'] }) {
  const option: Partial<RectProps> = await getBaseCommonProps(data, opt)
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5
  const radian = Math.atan2(width, height)
  const sizeY = height * radius
  const sizeX = Math.tan(radian) * sizeY

  if (radius == 1) {
    return new Rect(option)
  }

  const path = `
    M 0 ${sizeY} 
    L ${sizeX} 0 
    L ${width} 0
    L 0 ${height}
    Z`

  return new Path(path, option)
}