import { useState, useContext, useEffect } from 'react';
import { Modal, Message, Form, Input, Select, InputTag, InputNumber } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import uploadFile, { typeEnum } from '@/utils/uploadFile';
import { GlobalContext } from '@/hooks/context';

import { putTempEdit } from '@/api/template';

import './editModal.less';

export default (props: any) => {
	const { info, visible, styleList, typeList, colorList, setVisible, onCallback } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const { cdnUrl } = useContext(GlobalContext);

	const [loading, setLoading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState('');

	// 上传图片
	const handleUpPreview = async (e: Event) => {
		try {
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			if (file) {
				const info: any = await uploadFile(file, typeEnum.preview);
				setPreviewUrl(info.data.key);
				form.setFieldsValue({
					preview: info.data.key
				});
			}
		} catch (error: any) {
			console.log(error);
			Message.error(error?.msg || error);
		}
	};

	const handleSave = async () => {
		try {
			const values = await form.validate();
			console.log('values', values);
			setLoading(true);
			const res = await putTempEdit(info.id, {
				...values,
				price: values.price * 100,
				preview: previewUrl
			});
			setLoading(false);
			if (res.code == 200) {
				Message.success('更新成功');
				onCallback();
				hamdleCancel();
			}
		} catch (error) {}
	};
	const hamdleCancel = () => {
		setVisible(false);
		setPreviewUrl('');
		setLoading(false);
	};

	useEffect(() => {
		if (visible && info) {
			const style = styleList.find((item: any) => item.label == info.style);
			const type = typeList.find((item: any) => item.label == info.type);
			const color = colorList.find((item: any) => item.label == info.color);
			form.setFieldsValue({
				name: info.name,
				style: style.value,
				type: type.value,
				color: color.value,
				tags: info.tags.split(','),
				price: info.price / 100,
				preview: info.preview
			});
			if (info.preview.indexOf('?') > -1) {
				setPreviewUrl(info.preview.split('?')[0]);
			} else {
				setPreviewUrl(info.preview);
			}
		}
	}, [visible]);

	return (
		<Modal
			wrapClassName="edit-template-modal"
			title="模板信息编辑"
			visible={visible}
			confirmLoading={loading}
			onOk={handleSave}
			onCancel={hamdleCancel}
			autoFocus={false}
			focusLock={true}
			style={{ width: '540px' }}>
			<Form form={form}>
				<FormItem label="模板名称" field="name" rules={[{ required: true, message: '请输入名称' }]}>
					<Input placeholder="请输入模板名称" />
				</FormItem>
				<FormItem label="模板风格" field="style" rules={[{ required: true, message: '请选择风格' }]}>
					<Select options={styleList} placeholder="请选择模板风格" allowClear />
				</FormItem>

				<FormItem label="行业类型" field="type" rules={[{ required: true, message: '请选择行业类型' }]}>
					<Select options={typeList} placeholder="请选择行业类型" allowClear />
				</FormItem>
				<FormItem label="模板色系" field="color" rules={[{ required: true, message: '请选择色系' }]}>
					<Select options={colorList} placeholder="请选择模板色系" allowClear />
				</FormItem>

				<FormItem label="模板标签" field="tags" rules={[{ required: true }]}>
					<InputTag allowClear placeholder="请输入标签后回车" />
				</FormItem>

				<FormItem label="模板价格" field="price" rules={[{ required: true }]}>
					<InputNumber prefix="¥" placeholder="请输入定价" />
				</FormItem>

				<FormItem label="模板焦点图" field="preview" rules={[{ required: true, message: '请选择风格' }]}>
					<div className="edit-template-btn">
						<input className="upload-file" type="file" onChange={(e: any) => handleUpPreview(e)} name="" id="" />
						{previewUrl ? (
							<img src={cdnUrl + previewUrl + '?imageView2/1/w/480/h/270'} alt="" />
						) : (
							<span>
								<IconPlus />
								上传图片
							</span>
						)}
					</div>
				</FormItem>
			</Form>
		</Modal>
	);
};
