import { Line } from 'fabric';

import { LineData } from '@/types/element';
import { getBaseCommonProps } from '../helper';
import { GetElementOption } from '../types';

export default async function getLine(data: LineData, { theme }: GetElementOption) {
	const option = await getBaseCommonProps(data, { theme });
	const borderWidth: number = data.border?.width || 0;
	const { top, left, width, height } = data;

	const line = new Line([left, top, left + width, top + height], {
		...option,
		left: left - borderWidth / 2,
		top: top - borderWidth / 2
	});
	if (data.rot) {
		line.rotate(data.rot);
	}

	return line;
}
